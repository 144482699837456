import axios from '../../utils/axios';
import { toast } from 'react-toastify';


export const TYPE_SET_BUTTON = 'TYPE_SET_BUTTON';
export const TYPE_CREATE_BUTTON = 'TYPE_CREATE_BUTTON';
export const TYPE_SET_NUMBER = 'TYPE_SET_NUMBER';
export const TYPE_DELETE_BUTTON = 'TYPE_DELETE_BUTTON';

export const TYPE_SET_CATEGORY_PROMPT = 'TYPE_SET_CATEGORY_PROMPT';

export const TYPE_UPDATE_BUTTON_BY_ID = 'TYPE_UPDATE_BUTTON_BY_ID';


export const getCategoryPrompt = (isRefresh = true, params = {}) => {
	return async dispatch => {
		try {
			const payload = await axios.get(`/api/v1/chatgpt/prompt-category`, {
				params
			});

			dispatch({
				type: TYPE_SET_CATEGORY_PROMPT,
				payload: {
					category: payload?.results || [],
					isRefresh
				}
			});
			return payload.results;

		} catch (e) {
			toast.error(`An error has occurred`, {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true

			});
			return [];
		}
	};
};


export const getButtonList = (isRefresh = true, params = {}) => {
	return async dispatch => {
		try {
			const payload = await axios.get(`/api/v1/chatgpt/compare-list`, {
				params
			});

			dispatch({
				type: TYPE_SET_BUTTON,
				payload: {
					buttons: payload || [],
					isRefresh
				}
			});
		} catch (e) {
			toast.error(`An error has occurred`, {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true

			});
		}
	};
};


export const getButtonNumbers = () => {
	return async dispatch => {
		try {
			const payload = await axios.get(`/api/v1/chatgpt/number-list`);

			dispatch({
				type: TYPE_SET_NUMBER,
				payload: {
					numbers: payload.results
				}
			});
			return payload.results;
		} catch (e) {
			toast.error(`An error has occurred`, {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true

			});
		}
	};
};


export const createButton = data => {
	return async dispatch => {
		try {
			const payload = await axios.post(`/api/v1/chatgpt/compare-list`, data);

			dispatch({
				type: TYPE_CREATE_BUTTON,
				payload: payload
			});
		} catch (e) {
			toast.error(`An error has occurred`, {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true

			});
		}
	};
};


export const deleteButton = id => {
	return async dispatch => {
		try {
			await axios.delete(`/api/v1/chatgpt/button-text/${id}`);

			dispatch({
				type: TYPE_DELETE_BUTTON,
				payload: { buttonId: id }
			});
		} catch (e) {
			toast.error(`An error has occurred`, {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true

			});
		}
	};
};


export const updateButton = (id, data) => {
	return async dispatch => {
		try {
			await axios.put(`/api/v1/chatgpt/button-text/${id}`, data);

			dispatch({
				type: TYPE_UPDATE_BUTTON_BY_ID,
				payload: {
					id: id,
					title: data.title,
					text_gpt: data.text_gpt
				}
			});
		} catch (e) {
			toast.error(`An error has occurred`, {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true

			});
		}
	};
};
