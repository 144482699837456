import axios from '../../utils/axios';
import { toast } from 'react-toastify';
import { TYPE_ADD_PARTICIPANT_TO_CURRENT_TASK } from './message';
import { TYPE_SET_IS_PARTICIPANT } from './task';


export const TYPE_SET_PARTICIPATION = 'TYPE_SET_PARTICIPATION';

export const TYPE_ADD_PARTICIPATION = 'TYPE_ADD_PARTICIPATION';

export const getParticipation = (chatId, isRefresh = true, params = {}) => {
	return async dispatch => {
		try {
			const payload = await axios.get(`/api/v1/chatgpt/task-participants/${chatId}`, {
				params
			});

			dispatch({
				type: TYPE_SET_PARTICIPATION,
				payload: {
					participation: payload.results || [],
					isRefresh
				}
			});
		} catch (e) {
			toast.error(`An error has occurred`, {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true
			});
		}
	};
};

export const createInvite = (data) => {
	return async dispatch => {
		try {
			const payload = await axios.post(`/api/v1/chatgpt/task-invite`, data);

			if (payload?.user) {
				dispatch({
					type: TYPE_ADD_PARTICIPATION,
					payload: payload?.user
				});
			}

			if (payload?.user) {
				dispatch({
					type: TYPE_ADD_PARTICIPANT_TO_CURRENT_TASK,
					payload: payload?.user?.user
				});
				dispatch({
					type: TYPE_SET_IS_PARTICIPANT,
					payload: { id: Number(data.task_id) }
				});
			}

			if (
				payload?.message
			) {
				toast.success(payload.message, {
					position: 'top-center',
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true
				});
			}


		} catch (e) {
			toast.error(`An error has occurred`, {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true
			});
		}
	};
};

