import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';

import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import Lottie from 'lottie-react';
import computer from '../../static/animation/computer.json';


const BlockMobileLayout = ({ children }) => {

	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		const userAgent = navigator.userAgent || navigator.vendor;

		if (/android/i.test(userAgent) || // Covers all Android devices
			(/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) || // Correctly groups all iOS devices, including iPad
			/windows phone/i.test(userAgent) || // Covers Windows Phone devices
			/BlackBerry|BB10/i.test(userAgent) || // Covers BlackBerry devices
			/SymbianOS/i.test(userAgent) || // Covers Symbian devices
			/Opera Mini/i.test(userAgent) || // Covers devices using Opera Mini
			/IEMobile|WPDesktop/i.test(userAgent) || // Covers Internet Explorer Mobile devices
			/webOS/i.test(userAgent) || // Covers webOS devices
			/bada/i.test(userAgent) || // Covers Samsung Bada devices
			/Kindle|Silk/i.test(userAgent) || // Covers Amazon Kindle devices
			/Tizen/i.test(userAgent) || // Covers devices running Tizen
			/Vivaldi/i.test(userAgent) || // Covers Vivaldi browser on mobile devices
			/FxiOS/i.test(userAgent) || // Covers Firefox on iOS
			/CriOS/i.test(userAgent) // Covers Chrome on iOS
		) {
			setIsMobile(true);
		}
	}, []);

	if (isMobile) {
		return (
			<Box sx={{ background: 'white', width: '100%', height: '100%' }}>
				<Typography sx={{
					p: 2,
					textAlign: 'center'
				}}>
					Please note that to fully utilize the Editor features, it is recommended to access it through a PC.
				</Typography>
				<Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
					<Box sx={{ height: '30%', width: '30%' }}>
						<Lottie animationData={computer} loop={true} />
					</Box>
				</Box>
			</Box>
		);

	}

	return (
		<Box
			sx={{
				flex: 1,
				height: '100%',
				overflow: 'auto'
			}}
		>
			{children || <Outlet />}
		</Box>
	);
};

BlockMobileLayout.propTypes = {
	children: PropTypes.node
};

export default BlockMobileLayout;
