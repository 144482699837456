import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import { Box, Grid, IconButton, Typography, useTheme } from '@mui/material';
import { PlanCard } from '../../content/overview/SelectPlan';
import { useDispatch, useSelector } from 'react-redux';
import { getSubscriptionPlan, updatePurchaseModal } from '../../store/actions';
import axios from '../../utils/axios';
import { loadStripe } from '@stripe/stripe-js';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';
import logo from '../../static/image/logo_image.png';


const stipePublishKey = process.env.REACT_APP_STIPE_PUBLISH_KEY || '';
const stripePromise = loadStripe(stipePublishKey);


export default function PurchaseModal({ open, onClose }) {
	const plans = useSelector(state => state.payment.plans);
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(null);
	const theme = useTheme();


	const subscription = useSelector(state => state.user.accountInfo?.subscription);

	const handleCheckout = async (price_id) => {
		setLoading(price_id);
		if (subscription.end_date === null || subscription.status === 'inactive') {
			// Создаем новую подписку
			const stripe = await stripePromise;

			const postData = {
				subscriptionPlanId: price_id
			};


			window.ire('generateClickId', function(clickid) {
				postData.impact_clickid = clickid;
			});

			const response = await axios.post(`/api/v1/payment/create-payment-session`,
				postData);

			if (response?.sessionId) {
				const result = await stripe.redirectToCheckout({
					sessionId: response.sessionId
				});


				if (result.error) {
					// Обработка ошибок
					console.log(result.error.message);
				}
			}
		} else {
			try {
				const response = await axios.put('/api/v1/payment/update-subscription', {
					subscriptionPlanId: price_id
				});
				if (response) {
					toast.success('Your Subscription Plan Has Been Successfully Updated - Changes Will Take Effect Within One Minute', {
						position: 'top-center',
						autoClose: 3000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true
					});
					dispatch(updatePurchaseModal(false));
				}
			} catch (e) {

			}

			// Здесь может быть код для обработки ответа об обновлении подписки
		}
		setLoading(null);
	};
	const [pricingPeriod,] = useState('month'); // 'monthly' or 'yearly'

	// const handlePricingToggle = (period) => {
	// 	setPricingPeriod(period);
	// };

	useEffect(() => {
		async function getPurchase() {
			await dispatch(getSubscriptionPlan(pricingPeriod));
		}

		getPurchase();
	}, [dispatch, pricingPeriod]);


	return (
		<Modal open={open} onClose={onClose}>
			<Box sx={{
				position: 'absolute',
				top: '50%',
				left: '50%',
				transform: 'translate(-50%, -50%)',
				bgcolor: 'background.paper',
				boxShadow: 24,
				p: 2,
				borderRadius: '10px',
				width: '60vw',
				maxHeight: '90vh', // Limit the height
				overflowY: 'auto', // Enable scrolling
				[theme.breakpoints.down('sm')]: {
					width: '90%' // Wider on small screens
				}
			}}>
				<IconButton
					onClick={() => {
						onClose();
					}}
					sx={{
						position: 'absolute',
						top: 10,
						right: 10,
						zIndex: 9999
					}}
				>
					<CloseIcon />
				</IconButton>
				<img src={logo} style={{
					width: '140px',
					height: '25px'
				}} alt={'logo'} />
				<Box sx={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'flex-start',
					width: '100%'
				}}>

					<Box sx={{
						flexGrow: 1,
						display: 'flex',
						justifyContent: 'center'
					}}>
						<Typography
							sx={{ mb: 1, fontSize: '26px', fontWeight: '600', textAlign: 'center' }}
						>
							Choose Your Plan
						</Typography>
					</Box>
				</Box>
				{/*<Box sx={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>*/}
					{/*<Box sx={{*/}
					{/*	display: 'flex', justifyContent: 'center', mb: 3,*/}
					{/*	borderRadius: '18px',*/}
					{/*	background: '#EAF4FF'*/}
					{/*}}>*/}
					{/*	<Button*/}
					{/*		sx={{*/}
					{/*			background: pricingPeriod === 'month' ? '#444CC6' : '#EAF4FF',*/}
					{/*			borderRadius: '99px'*/}
					{/*		}}*/}
					{/*		// color={isActive('monthly')}*/}
					{/*		onClick={() => handlePricingToggle('month')}*/}
					{/*	>*/}
					{/*		<Typography sx={{*/}
					{/*			color: pricingPeriod === 'month' ? 'white' : '#444CC6',*/}
					{/*			// opacity: pricingPeriod === 'monthly' ? 1 : 0.3,*/}
					{/*			fontWeight: '500'*/}

					{/*		}}>Monthly</Typography>*/}
					{/*	</Button>*/}
					{/*	<Button*/}
					{/*		sx={{*/}
					{/*			background: pricingPeriod === 'year' ? '#444CC6' : '#EAF4FF',*/}
					{/*			borderRadius: '99px'*/}
					{/*		}}*/}
					{/*		// color={isActive('yearly')}*/}
					{/*		onClick={() => handlePricingToggle('year')}*/}
					{/*	>*/}
					{/*		<Typography sx={{*/}
					{/*			color: pricingPeriod === 'year' ? 'white' : '#444CC6',*/}
					{/*			// opacity: pricingPeriod === 'monthly' ? 1 : 0.3,*/}
					{/*			fontWeight: '500'*/}

					{/*		}}>Yearly</Typography>*/}

					{/*	</Button>*/}


					{/*</Box>*/}

				{/*</Box>*/}

				<Grid container spacing={2} justifyContent="center">
					{plans.map((plan, index) => (
						<Grid item xs={12} sm={12} md={6} lg={6} key={index} sx={{}}>
							<PlanCard
								isLoading={loading === plan.id}
								plan={plan}
								handleCheckout={handleCheckout} />
						</Grid>
					))}
				</Grid>
			</Box>
		</Modal>
	);
}
