import React, { useState } from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
	Card,
	Typography,
	IconButton,
	Button,
	Box,
	Modal,
	List,
	ListItem,
	ListItemIcon,
	ListItemText
} from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import CreateIcon from '@mui/icons-material/Create';
import SummarizeIcon from '@mui/icons-material/Summarize';
import CloseIcon from '@mui/icons-material/Close';


const HintCard = () => {

	const [open, setOpen] = useState(false);

	const handleClose = () => setOpen(false);

	return (
		<>
			<Card sx={{
				maxWidth: 380,
				background: 'linear-gradient(135deg, #667eea 0%, #764ba2 100%)',
				borderRadius: '16px',
				boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
				padding: '16px',
				color: 'white',
				textAlign: 'center', // Align text and content in the center
				'&:hover': { // Hover styles for the card
					transform: 'translateY(-5px)', // Slight upward lift
					boxShadow: '0 12px 36px 0 rgba(31, 38, 135, 0.47)' // Enhanced shadow for lift effect
				},
				transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out' // Smooth transition for the hover effect
			}}>
				<Box sx={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
					<Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '8px', textAlign: 'center' }}>
						HINT
					</Typography>
					<Box aria-label="close" sx={{ color: 'white', position: 'absolute', right: 0 }}>
						<HelpOutlineIcon />
					</Box>
				</Box>
				<Typography variant="body1" sx={{ marginBottom: '24px', lineHeight: 1.5, fontWeight: 500 }}>
					Improve your <span style={{ fontWeight: 600, color: '#ADD8E6' }}>document review</span> process with
					the help
					of <span style={{ fontWeight: 600, color: '#ADD8E6' }}>summarization</span>, <span
					style={{ fontWeight: 600, color: '#ADD8E6' }}>analysis</span>, and
					<span style={{ fontWeight: 600, color: '#ADD8E6' }}> interactive prompts</span> to make your
					workflow
					more efficient.
				</Typography>
				<Button onClick={() => setOpen(true)} variant="contained" sx={{
					background: 'linear-gradient(135deg, #667eea 0%, #8a7ed6 50%, #764ba2 100%)',
					borderRadius: '20px',
					textTransform: 'none',
					boxShadow: '0 10px 26px 0 rgba(31, 38, 135, 0.37)',
					position: 'relative',
					overflow: 'hidden',
					'&:hover': {
						transform: 'translateY(-3px)', // Button moves up slightly
						background: 'linear-gradient(135deg, #5b6ee1 0%, #8860d0 100%)', // Intensifying the color slightly
						boxShadow: '0 10px 20px 0 rgba(31, 38, 135, 0.45)' // More pronounced shadow
					},
					'&:before': { // Pseudo-element for the glow effect
						content: '""',
						position: 'absolute',
						top: -10,
						left: -10,
						width: 'calc(100% + 20px)',
						height: 'calc(100% + 20px)',
						borderRadius: '20px',
						background: 'radial-gradient(circle, rgba(103, 110, 238, 0.4) 0%, rgba(118, 75, 162, 0.35) 70%)', // Radial gradient for a glow effect
						opacity: 0,
						transition: 'opacity 0.3s ease-in-out',
						zIndex: -1 // Ensure the glow is under the button content
					},
					'&:hover:before': {
						opacity: 1 // Glow appears on hover
					}
				}}>
					Explore
				</Button>

			</Card>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					width: '100%',
					maxWidth: '600px',
					bgcolor: 'background.paper',
					borderRadius: 2,
					boxShadow: 24,
					p: 3
				}}>
					<IconButton
						aria-label="close"
						onClick={handleClose}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
							color: 'grey.600'
						}}
					>
						<CloseIcon />
					</IconButton>
					<Typography id="modal-modal-title" variant="h3" component="h2"
					            sx={{ mb: 2, fontWeight: 'bold', textAlign: 'center' }}>
						Streamline Your Document Review
					</Typography>

					<List sx={{ width: '100%' }}>
						{['Analysis', 'Drafting', 'Summary'].map((text, index) => (
							<React.Fragment key={text}>
								<ListItem sx={{
									alignItems: 'flex-start',
									backgroundColor: 'rgba(0, 105, 180, 0.1)',
									borderRadius: '5px',
									boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
									mb: 2,
									animation: `fadeInUp 0.8s ${0.2 * index}s forwards`
								}}
								>
									<ListItemIcon sx={{ minWidth: '40px', color: 'info.main' }}>
										{index === 0 ? <SearchIcon /> : index === 1 ? <CreateIcon /> :
											<SummarizeIcon />}
									</ListItemIcon>
									<ListItemText
										primaryTypographyProps={{ fontWeight: 'bold' }}
										primary={text}
										secondary={
											text === 'Analysis' ? 'Ask your own questions, use our follow-up questions based on the document context, or utilize our pre-set prompt library for common document analysis use cases.' :
												text === 'Drafting' ? 'Based on your uploaded document, draft an email to your client, organize events in chronological order, and anything else you may need.' :
													'Extract the most important and relevant information from the document, condense it into a concise summary.'
										}
									/>
								</ListItem>

							</React.Fragment>
						))}
					</List>
				</Box>
			</Modal>
		</>
	);
};

export default HintCard;
