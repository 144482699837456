import { TYPE_SET_LOADING_MESSAGE } from '../actions';


const initialState = {
	type: 'message',
	isLoading: false
};


const typeTypingReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {

		case TYPE_SET_LOADING_MESSAGE:
			return payload;

		default:
			return state;
	}
};

export default typeTypingReducer;
