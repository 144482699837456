import axios from '../../utils/axios';
import { toast } from 'react-toastify';
import { addChatToListChat } from './chat';
import { TYPE_SET_FILE_EXIST } from './tips';


export const TYPE_PREPEND_CHAT_MESSAGE = 'TYPE_PREPEND_CHAT_MESSAGE';

export const TYPE_ADD_MESSAGE = 'TYPE_ADD_MESSAGE';
export const TYPE_SET_CHAT_MESSAGE = 'TYPE_SET_CHAT_MESSAGE';
export const TYPE_ADD_REPLY_MESSAGE = 'TYPE_SET_REPLY_MESSAGE';
export const TYPE_DELETE_REPLY_MESSAGE = 'TYPE_DELETE_REPLY_MESSAGE';
export const TYPE_ADD_REPLY_TEXT = 'TYPE_ADD_REPLY_TEXT';
export const TYPE_DELETE_REPLY_TEXT = 'TYPE_DELETE_REPLY_TEXT';
export const TYPE_DELETE_MESSAGE = 'TYPE_DELETE_MESSAGE';

export const TYPE_TOGGLE_TYPING = 'TYPE_TOGGLE_TYPING';
export const TYPE_TOGGLE_IS_NEXT_LOADING = 'TYPE_TOGGLE_IS_NEXT_LOADING';
export const TYPE_SET_IS_LOADING = 'TYPE_SET_IS_LOADING';
export const TYPE_TOGGLE_CONNECT = 'TYPE_TOGGLE_CONNECT';
export const TYPE_SET_CURRENT_CHAT = 'TYPE_SET_CURRENT_CHAT';

export const TYPE_SET_REFERENCE = 'TYPE_SET_REFERENCE';

export const TYPE_CLEAR_MESSAGE = 'TYPE_CLEAR_MESSAGE';
export const TYPE_ADD_PARTICIPANT_TO_CURRENT_TASK = 'TYPE_ADD_PARTICIPANT_TO_CURRENT_TASK';
export const TYPE_INCREMENT_EMOTION_COUNT = 'TYPE_INCREMENT_EMOTION_COUNT';

export const TYPE_SET_EMOJI_MESSAGE = 'TYPE_SET_EMOJI_MESSAGE';
export const INCREMENT_MESSAGE_EMOJI = 'INCREMENT_MESSAGE_EMOJI';
export const DECREMENT_MESSAGE_EMOJI = 'DECREMENT_MESSAGE_EMOJI';


export const addReplyMessage = (chatId, message, type) => {
	return dispatch => {
		try {
			dispatch({
				type: TYPE_ADD_REPLY_MESSAGE,
				payload: { value: { ...message, type_reply: type }, key: chatId }
			});

		} catch (e) {
			alert('Error Task');
		}
	};
};


export const addReplyText = (chatId, message) => {
	return dispatch => {
		try {
			dispatch({
				type: TYPE_ADD_REPLY_TEXT,
				payload: { value: { ...message }, key: chatId }
			});
		} catch (e) {
			alert('Error Task');
		}
	};
};

export const deleteReplyText = (chatId) => {
	return dispatch => {
		try {
			dispatch({
				type: TYPE_DELETE_REPLY_TEXT,
				payload: { key: chatId }
			});
		} catch (e) {

		}
	};
};


export const deleteReplyMessage = (chatId) => {
	return dispatch => {
		try {
			dispatch({
				type: TYPE_DELETE_REPLY_MESSAGE,
				payload: { key: chatId }
			});
		} catch (e) {

		}
	};
};

export const incrementEmotion = (messageId, emotionType) => {
	return dispatch => {
		try {

			dispatch({
				type: INCREMENT_MESSAGE_EMOJI,
				payload: { idMessage: messageId, emotionType: emotionType }
			});
		} catch (e) {
			console.log(e);
		}
	};
};

export const decrementEmotion = (messageId, emotionType) => {
	return dispatch => {
		try {
			dispatch({
				type: DECREMENT_MESSAGE_EMOJI,
				payload: { idMessage: messageId, emotionType: emotionType }
			});
		} catch (e) {
			console.log(e);
		}
	};
};


export const setMessageChat = (messageId, isRefresh = true, params = {}) => {
	return async dispatch => {
		try {
			dispatch({
				type: TYPE_CLEAR_MESSAGE
			});
			const payload = await axios.get(`/api/v1/chatgpt/messages/${messageId}`,
				{ params });
			dispatch({
				type: TYPE_SET_CHAT_MESSAGE,
				payload: {
					messages: payload.results || [],
					isRefresh,
					next: payload.next
				}
			});

			if (payload?.results?.length > 0) {
				const result = {};
				for (let i = 0; i < payload?.results.length; i++) {
					const item = payload?.results[i];
					result[item.id] = item.emotions;
				}
				dispatch({ type: TYPE_SET_EMOJI_MESSAGE, payload: result });

			}
		} catch (e) {
			dispatch({
				type: TYPE_SET_IS_LOADING,
				payload: { isLoading: false }
			});
			dispatch({
				type: TYPE_CLEAR_MESSAGE
			});
			//   toast.error(`Chat access denied`,  {
			//   position: "top-center",
			//   autoClose: 3000,
			//   hideProgressBar: true,
			//   closeOnClick: true,
			//   pauseOnHover: true,
			//   draggable: true,
			//
			// });
		} finally {

		}
	};
};

export const getCurrentChat = (chatId) => {
	return async dispatch => {
		try {
			const payload = await axios.get(`/api/v1/chatgpt/chat-info/${chatId}`);

			dispatch(addChatToListChat(payload));
			// dispatch({type: TYPE_SET_CURRENT_CHAT, payload:{chat: payload}})

		} catch (e) {

			toast.error(`Error`, {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true

			});
		} finally {

		}
	};
};


export const getFileExist = (chatId) => {
	return async dispatch => {
		try {
			const payload = await axios.get(`/api/v1/chatgpt/task/${chatId}/file-exists`);
			dispatch({ type: TYPE_SET_FILE_EXIST, payload: payload.exists });


		} catch (e) {

			toast.error(`Error`, {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true

			});
		} finally {

		}
	};
};


export const prependChatMessage = (message) => {
	return dispatch => {
		dispatch({
			type: TYPE_PREPEND_CHAT_MESSAGE,
			payload: { message }
		});
	};
};

export const addChatMessage = (messages) => {
	return dispatch => {
		dispatch({
			type: TYPE_ADD_MESSAGE,
			payload: { messages }
		});
	};
};


export const toggleTyping = (isUpdate) => {
	return dispatch => {
		dispatch({
			type: TYPE_TOGGLE_TYPING,
			payload: { status: isUpdate }
		});
	};
};


export const toggleCurrentChat = (chat) => {
	return dispatch => {
		dispatch({
			type: TYPE_SET_CURRENT_CHAT,
			payload: { chat: chat }
		});
	};
};

export const togglFirstLoading = (isLoading = false) => {
	return dispatch => {
		dispatch({
			type: TYPE_SET_IS_LOADING,
			payload: { isLoading: isLoading }
		});
	};
};


export const togglConnect = (connect) => {
	return dispatch => {
		dispatch({
			type: TYPE_TOGGLE_CONNECT,
			payload: { status: connect }
		});
	};
};


export const getReference = messageId => {
	return async dispatch => {
		try {
			const payload = await axios.get(`/api/v1/chatgpt/content-file/${messageId}`);

			dispatch({
				type: TYPE_SET_REFERENCE,
				payload: payload
			});
		} catch (e) {
			toast.error(`An error has occurred`, {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true
			});
		}
	};
};


export const deleteMessage = (chatId, messageId) => {
	return async dispatch => {
		try {

			await axios.delete(`/api/v1/chatgpt/chat/${chatId}/message/${messageId}/delete`);

			dispatch({
				type: TYPE_DELETE_MESSAGE,
				payload: { id: messageId, chat: chatId }
			});
		} catch (e) {
			toast.error(`An error has occurred`, {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true
			});
		}
	};
};
