import { DECREMENT_MESSAGE_EMOJI, INCREMENT_MESSAGE_EMOJI, TYPE_SET_EMOJI_MESSAGE } from '../actions';


const initialState = {};


const emojiMessageReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case TYPE_SET_EMOJI_MESSAGE:
			return { ...state, ...payload };
		case INCREMENT_MESSAGE_EMOJI:
			const { idMessage, emotionType } = payload;

			const messageEmotions = state[idMessage] || [];
			const emotionIndex = messageEmotions.findIndex(emotion => emotion.type === emotionType);

			if (emotionIndex !== -1) {
				// Increment count
				messageEmotions[emotionIndex] = {
					...messageEmotions[emotionIndex],
					count: (messageEmotions[emotionIndex].count || 0) + 1,
					isPressed: true
				};
			} else {
				// Add new emotion with initial count
				messageEmotions.push({ type: emotionType, isPressed: true, count: 1 });
			}

			return {
				...state,
				[idMessage]: [...messageEmotions]
			};

		case DECREMENT_MESSAGE_EMOJI:

			const messageEmotions1 = state[payload.idMessage] || [];
			const emotionIndex1 = messageEmotions1.findIndex(emotion => emotion.type === payload.emotionType);

			if (emotionIndex1 !== -1) {
				const newCount = messageEmotions1[emotionIndex1].count - 1; // Corrected here

				if (newCount <= 0) {
					// Remove emotion if count is 0 or less
					messageEmotions1.splice(emotionIndex1, 1);
				} else {
					// Decrement count
					messageEmotions1[emotionIndex1] = {
						...messageEmotions1[emotionIndex1],
						count: newCount,
						isPressed: false
					};
				}
			}

			return {
				...state,
				[payload.idMessage]: [...messageEmotions1]
			};

		default:
			return state;
	}
};
export default emojiMessageReducer;
