import { TYPE_ADD_TEXT_CHUNK, TYPE_DELETE_CHUNKS } from '../actions';


const initialState = {
	typing: '',
	errorTyping: false
};


const typingMessageReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {

		case TYPE_ADD_TEXT_CHUNK:
			return {
				...state,
				typing: state.typing + payload
			};

		case TYPE_DELETE_CHUNKS:
			return {
				...state,
				typing: ''
			};
		default:
			return state;
	}
};

export default typingMessageReducer;
