import axios from '../../utils/axios';
import { TYPE_CREATE_FILE, TYPE_CREATE_FILE_BY_FOLDER } from './drive';
import { toast } from 'react-toastify';


export const FILE_UPLOAD_SUCCESS = 'FILE_UPLOAD_SUCCESS';
export const TYPE_DELETE_FILE = 'TYPE_DELETE_FILE';

export const TYPE_DELETE_ALL_FILE = 'TYPE_DELETE_ALL_FILE';


export const uploadOneFile = (file, name, onProgress, folder, taskId) => {
	return async (dispatch) => {
		const formData = new FormData();
		formData.append('file', file);
		formData.append('name', name);
		if (file?.size) {
			formData.append('size', file.size);
		}

		if (taskId) {
			formData.append('taskId', taskId);
		}

		if (folder) {
			formData.append('folder', folder);
		}

		try {
			const response = await axios.post('/api/v1/chatgpt/archive', formData, {
				onUploadProgress: (progressEvent) => {
					const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
					onProgress(percentCompleted);
				}
			});

			dispatch({
				type: FILE_UPLOAD_SUCCESS,
				payload: response
			});

			dispatch({
				type: TYPE_CREATE_FILE,
				payload: { file: response }
			});
			if (folder) {
				dispatch({
					type: TYPE_CREATE_FILE_BY_FOLDER,
					payload: { file: response }
				});
			}
			return response;

		} catch (error) {
			if (Array.isArray(error) && error.length > 0) {
				toast.error(error[0], {
					position: 'top-center',
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true
				});
			} else {
				toast.error(`An error has occurred`, {
					position: 'top-center',
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true

				});
			}
		}
	};
};


export const uploadImage = (file, name, onProgress, folder, taskId) => {
	return async (dispatch) => {
		const formData = new FormData();
		formData.append('image', file);
		formData.append('name', name);

		if (taskId) {
			formData.append('taskId', taskId);
		}

		try {
			const response = await axios.post('/api/v1/chatgpt/image-list', formData, {
				onUploadProgress: (progressEvent) => {
					const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
					onProgress(percentCompleted);
				}
			});

			dispatch({
				type: FILE_UPLOAD_SUCCESS,
				payload: response
			});
			//
			// dispatch({
			//     type: TYPE_CREATE_FILE,
			//     payload: {file:response}
			// })
			// if (folder){
			//      dispatch({
			//     type: TYPE_CREATE_FILE_BY_FOLDER,
			//     payload: {file:response}
			//    })
			// }
			return response;

		} catch (error) {
			if (Array.isArray(error) && error.length > 0) {
				toast.error(error[0], {
					position: 'top-center',
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true
				});
			} else {
				toast.error(`An error has occurred`, {
					position: 'top-center',
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true

				});
			}
		}
	};
};


export const setUploadFIle = (file) => {
	return async (dispatch) => {

		dispatch({
			type: FILE_UPLOAD_SUCCESS,
			payload: file
		});
		return file;

	};
};

export const deleteFile = (fileId) => {
	return async (dispatch) => {
		try {
			// await axios.delete(`/api/v1/chatgpt/archive/${fileId}`);

			dispatch({
				type: TYPE_DELETE_FILE,
				payload: { id: fileId }
			});
		} catch (error) {
			console.log(error);
		}
	};
};


export const deleteAllFile = () => {
	return (dispatch) => {
		try {
			// await axios.delete(`/api/v1/chatgpt/archive/${fileId}`);

			dispatch({
				type: TYPE_DELETE_ALL_FILE
			});
		} catch (error) {
			console.log(error);
		}
	};
};





