
import axios from "../../utils/axios";
import {toast} from "react-toastify";

export const TYPE_SET_SUBSCRIPTION_PLAN = 'TYPE_SET_SUBSCRIPTION_PLAN';


export const TYPE_SET_MY_SUBSCRIPTION = 'TYPE_SET_MY_SUBSCRIPTION';

export const TYPE_SET_MY_INVOICES = 'TYPE_SET_MY_INVOICES';


export const TYPE_UPDATE_MODAL = 'TYPE_UPDATE_MODAL';

export const TYPE_SET_MY_CARDS = 'TYPE_SET_MY_CARDS'

export const TYPE_UPDATE_SUBSCRIPTION_STATUS = 'TYPE_UPDATE_SUBSCRIPTION_STATUS'

export const TYPE_UPDATE_DISCOUNT_INFO = 'TYPE_UPDATE_DISCOUNT_INFO'

export const TYPE_UPDATE_CARD = 'TYPE_UPDATE_CARD'

export const TYPE_ADD_CARD = 'TYPE_ADD_CARD'

export const TYPE_DELETE_CARD = 'TYPE_DELETE_CARD'




export const getSubscriptionPlan = (duration) => {
  return async dispatch => {
    try {
      const payload = await axios.get(`/api/v1/payment/subscription-plan`,
          {params: {duration}});

      dispatch({
        type: TYPE_SET_SUBSCRIPTION_PLAN,
        payload: {
          plans: payload.results,
        },
      });
    } catch (e) {
        toast.error(`Error receiving my subscription. Try again later`,  {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };
};


export const getMySubscription = () => {
  return async dispatch => {
    try {
      const payload = await axios.get(`/api/v1/payment/my-subscription`);

      dispatch({
        type: TYPE_SET_MY_SUBSCRIPTION,
        payload: {
          subscription: payload,
        },
      });
    } catch (e) {
       toast.error(`Error receiving my subscription. Try again later`,  {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };
};


export const getMyInvoice = () => {
  return async dispatch => {
    try {
      const payload = await axios.get(`/api/v1/payment/my-invoices`);

      dispatch({
        type: TYPE_SET_MY_INVOICES,
        payload: {
          invoices: payload,
        },
      });
    } catch (e) {
       toast.error(`Error receiving my invoices. Try again later`,  {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };
};


export const deleteSubscription = () => {
  return async dispatch => {
    try {
      await axios.post(`/api/v1/payment/cancel-subscription`);

      dispatch({
        type: TYPE_UPDATE_SUBSCRIPTION_STATUS,
        payload: {
          newStatus: 'pending',
        },
      });

    } catch (e) {
       toast.error(`Error receiving. Try again later`,  {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };
};

export const applyPromoCode = (promo_code) => {
  return async dispatch => {
    try {
      const result = await axios.post(`/api/v1/payment/apply-promo-code`, {
        promo_code
      });

      console.log({ result });

      dispatch({
        type: TYPE_UPDATE_DISCOUNT_INFO,
        payload: result
      });

      toast.success('Yah!');
     await dispatch(getSubscriptionPlan('month'))
      dispatch(updatePurchaseModal(true))

    } catch (e) {
      if (e.error) {
        return toast.error(e.error, {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      }

      toast.error(`Error receiving. Try again later`, {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    }
  };
};


export const restoreSubscription = () => {
  return async dispatch => {
    try {
      await axios.post(`/api/v1/payment/restore-subscription`);

      dispatch({
        type: TYPE_UPDATE_SUBSCRIPTION_STATUS,
        payload: {
          newStatus: 'active',
        },
      });

    } catch (e) {
       toast.error(`Error receiving. Try again later`,  {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };
};



export const updatePurchaseModal = (purchaseModal) => {
  return async dispatch => {
   dispatch({
        type: TYPE_UPDATE_MODAL,
        payload: {
            purchaseModal: purchaseModal
        },
      });

  };
};




export const getCardInfo = () => {
  return async dispatch => {
    try {
      const data  = await axios.get(`/api/v1/payment/user-card-info`);

      dispatch({
        type: TYPE_SET_MY_CARDS,
        payload: {
            cards: data
        },
      });




    } catch (e) {
       toast.error(`Error receiving. Try again later`,  {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };
};

export const updateCard = (payload) => {
    return async dispatch => {
        try {

            await axios.put('api/v1/payment/update-name-payment-card', payload);

            dispatch({
                type: TYPE_UPDATE_CARD,
                payload
            });

            toast.success('Card Added Successfully')

        } catch (e) {
            if (e.message) {
                return toast.error(e.message);
            }

            return toast.error(`Error receiving. Try again later`);
        }
    };
};

export const changeDefaultCard = (payload) => {
	return async dispatch => {
		try {

			await axios.put('api/v1/payment/update-card', {
			payment_method_id: payload
			});

			dispatch({
				type: TYPE_UPDATE_CARD,
				payload
			});

			toast.success('Card Changed Successfully')

		} catch (e) {
			if (e.message) {
				return toast.error(e.message);
			}

			return toast.error(`Error receiving. Try again later`);
		}
	};
};



export const deleteCard = (paymentMethodId) => {
    return async dispatch => {
        try {
            const data = await axios.delete('api/v1/payment/delete-card/' + paymentMethodId);

			if(data?.status === 'deleted') {
				dispatch({
					type: TYPE_DELETE_CARD,
					payload: paymentMethodId,
				});

				return toast.success('Card Deleted Successfully')
			}

	        return toast.error(`Error receiving. Try again later`);
        } catch (e) {
            if (e.message) {
                return toast.error(e.message);
            }

            return toast.error(`Error receiving. Try again later`);
        }

    };
};



export const addCard = (payload) => {
    return async dispatch => {
        try {
            const data = await axios.post('api/v1/payment/add-payment-card',  {payment_method_id: payload.id});

            dispatch({
                type: TYPE_ADD_CARD,
                payload: data
            });

            toast.success('Card Added Successfully')
        } catch (e) {
            if (e.message) {
                return toast.error(e.message);
            }

            return toast.error(`Error receiving. Try again later`);
        }

    };
};


