import axios from '../../utils/axios';
import { toast } from 'react-toastify';


export const TYPE_SET_NOTIFICATION = 'TYPE_SET_NOTIFICATION';

export const TYPE_DISABLE_NOTIFICATION_LOADING = 'TYPE_DISABLE_NOTIFICATION_LOADING';

export const TYPE_SET_COUNT = 'TYPE_SET_COUNT';

export const TYPE_DELETE_ALL_COUNT = 'TYPE_DELETE_ALL_COUNT';


export const getNotification = (isRefresh = true) => {
	return async dispatch => {
		try {
			const payload = await axios.get(`/api/v1/notification/notification`);
			dispatch({
				type: TYPE_SET_NOTIFICATION,
				payload: {
					notification: payload?.results,
					isRefresh: isRefresh
				}
			});
			// return payload.results
		} catch (e) {
			toast.error(`An error has occurred`, {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true

			});
		} finally {
			dispatch({
				type: TYPE_DISABLE_NOTIFICATION_LOADING
			});

		}
	};
};


export const getCountNotification = () => {
	return async dispatch => {
		try {
			const payload = await axios.get(`/api/v1/notification/unread-notification`);
			dispatch({
				type: TYPE_SET_COUNT,
				payload: {
					count: payload?.unread_count
				}
			});
			// return payload.results
		} catch (e) {
			toast.error(`An error has occurred`, {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true

			});
		} finally {


		}
	};
};

export const deleteALlCountNotification = () => {
	return async dispatch => {
		try {
			dispatch({
				type: TYPE_SET_COUNT,
				payload: {
					count: 0
				}
			});
			// return payload.results
		} catch (e) {
			toast.error(`An error has occurred`, {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true

			});
		}
	};
};

