import { TYPE_GET_RECENTLY_TASK } from '../actions';


const getStoredTasks = () => {
	try {
		const storedTasks = localStorage.getItem('tasks');
		return storedTasks ? JSON.parse(storedTasks) : [];
	} catch (error) {
		return [];
	}
};

const initialState = getStoredTasks();

const recentlyTaskReducer = (state = initialState, action) => {
	switch (action.type) {
		case TYPE_GET_RECENTLY_TASK:
			return state;

		default:
			return state;
	}
};
export default recentlyTaskReducer;
