import { Box, Button, IconButton, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import CustomTextField from '../../../components/common/CustomTextField';


const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 600,
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 4,
	borderRadius: 1
};

export default function ModalFileRename({ handleClose, onSubmit, initialValues = {} }) {
	return (
		<Box sx={style}>
			<Formik
				initialValues={{
					name: '',
					...initialValues
				}}
				onSubmit={onSubmit}
			>
				<Form>
					<Box
						sx={{
							width: 500,
							maxWidth: '100%'
						}}
					>
						<IconButton
							onClick={handleClose}
							style={{
								position: 'absolute',
								top: 10,
								right: 10,
								color: '#D0D5DD'
							}}
						>
							<CloseIcon />
						</IconButton>
						<Typography variant={'h3'} sx={{ mb: 2 }}>{'Rename File'}</Typography>
						<CustomTextField fullWidth label="Enter File Name" name="name" />
						<Button type="submit" sx={{
							background: 'linear-gradient(99.97deg, #64ABFF 0%, #444CC6 102.14%)',
							color: 'white',
							fontSize: 14,
							mt: 2,
							pl: 3,
							pr: 3
						}}>{'Save'}</Button>
					</Box>
				</Form>
			</Formik>
		</Box>
	);
}
