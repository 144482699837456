import axios from '../../utils/axios';
import { toast } from 'react-toastify';


export const TYPE_SET_TASK = 'TYPE_SET_TASK';

export const TYPE_CREATE_TASK = 'TYPE_CREATE_TASK';


export const TYPE_DELETE_TASK = 'TYPE_DELETE_TASK';

export const TYPE_UPDATE_STATUS = 'TYPE_UPDATE_STATUS';

export const TYPE_SET_RECENTLY_TASK = 'TYPE_SET_RECENTLY_TASK';
export const TYPE_GET_RECENTLY_TASK = 'TYPE_GET_RECENTLY_TASK';

export const TYPE_SET_CURRENT_TASK = 'TYPE_SET_CURRENT_TASK';

export const TYPE_SET_PIN_POST = 'TYPE_SET_PIN_POST';

export const TYPE_SET_UN_PIN_POST = 'TYPE_SET_UN_PIN_POST';

export const TYPE_UPDATE_TASK_NAME = 'TYPE_UPDATE_TASK_NAME';

export const TYPE_SET_IS_PARTICIPANT = 'TYPE_SET_IS_PARTICIPANT';


export const getTasks = (isRefresh = true, params = {}) => {
	return async dispatch => {
		try {
			const payload = await axios.get(`/api/v1/chatgpt/task-list`, {
				params
			});

			dispatch({
				type: TYPE_SET_TASK,
				payload: {
					tasks: payload.results,
					recentlyTask: payload.recently_opened_tasks,
					isRefresh
				}
			});
			return payload?.next || null;
		} catch (e) {
			toast.error(`An error has occurred`, {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true
			});
		}
	};
};


export const createTask = data => {
	return async dispatch => {
		try {
			const payload = await axios.post(`/api/v1/chatgpt/task-list`, data);

			dispatch({
				type: TYPE_CREATE_TASK,
				payload: payload
			});
			return payload;
		} catch (e) {
			toast.error(`An error has occurred`, {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true
			});
		}
	};
};


export const analyzeDocument = (data) => {
	return async dispatch => {
		try {

			const payload = await axios.post(`/api/v1/chatgpt/analyze-document`, data);

			dispatch({
				type: TYPE_UPDATE_STATUS,
				payload: {
					id: data.taskId
				}
			});
			return payload;
		} catch (e) {
			toast.error(`An error has occurred`, {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true

			});
		}
	};
};


export const addRecentlyTask = (task) => {
	return async dispatch => {
		try {
			await axios.post(`/api/v1/chatgpt/recently-task`, { task_id: task.id });

			dispatch({
				type: TYPE_SET_RECENTLY_TASK,
				payload: task
			});
		} catch (e) {
			toast.error(`An error has occurred`, {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true
			});
		}
	};
};


export const getRecentlyTask = () => {
	return dispatch => {
		try {
			dispatch({
				type: TYPE_GET_RECENTLY_TASK
			});
		} catch (e) {
			toast.error(`An error has occurred`, {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true
			});
		}
	};
};


export const deleteTask = taskId => {
	return async dispatch => {
		try {
			const payload = await axios.delete(`/api/v1/chatgpt/task/${taskId}`);

			dispatch({
				type: TYPE_DELETE_TASK,
				payload: {
					id: taskId
				}
			});
			return payload;
		} catch (e) {
			toast.error(`An error has occurred`, {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true
			});
		}
	};
};


export const pinTask = taskId => {
	return async dispatch => {
		try {
			const payload = await axios.post(`/api/v1/chatgpt/pin-post/${taskId}`);

			dispatch({
				type: TYPE_SET_PIN_POST,
				payload: { id: taskId }
			});

			return payload;
		} catch (e) {
			toast.error(`An error has occurred`, {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true
			});
		}
	};
};

export const unPinTask = taskId => {
	return async dispatch => {
		try {
			const payload = await axios.delete(`/api/v1/chatgpt/pin-post/${taskId}`);
			dispatch({
				type: TYPE_SET_UN_PIN_POST,
				payload: { id: taskId }
			});
			return payload;
		} catch (e) {
			toast.error(`An error has occurred`, {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true
			});
		}
	};
};


export const renameTask = (taskId, data) => {
	return async dispatch => {
		try {
			const payload = await axios.put(`/api/v1/chatgpt/tasks/${taskId}/update_name`, data);

			dispatch({
				type: TYPE_UPDATE_TASK_NAME,
				payload: {
					id: taskId,
					name: data.name
				}
			});
			return payload;
		} catch (e) {
			toast.error(`An error has occurred`, {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true
			});
		}
	};
};
