import { TYPE_ADD_TASK_FILES, TYPE_DELETE_TASK_FILES, TYPE_SET_TASK_FILE } from '../actions';


const initialState = [];

const taskStoreReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case TYPE_SET_TASK_FILE:
			if (payload.isRefresh) {
				return payload.file;
			}
			return [...state, ...payload.file];


		case TYPE_ADD_TASK_FILES:
			return [...state, ...payload.file];

		case TYPE_DELETE_TASK_FILES:
			return [];

		// case  TYPE_CREATE_TASK:
		// return {drive: [...[payload], ...state.drive]}

		default:
			return state;
	}
};
export default taskStoreReducer;
