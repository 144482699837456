import { ADD_ACCESS_KEY, ADD_CHAT, CHANGE_IS_HASH } from '../actions/chat';


const initialState = {};


const chatsReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case ADD_CHAT:
			return {
				...state,
				[payload.id]: { ...payload }
			};

		case  ADD_ACCESS_KEY:
			if (state[payload.id]) {
				return {
					...state,
					[payload.id]: {
						...state[payload.id],
						accessKey: payload.accessKey
					}
				};
			}
			break;
		case CHANGE_IS_HASH:
			if (state[payload.id]) {
				return {
					...state,
					[payload.id]: {
						...state[payload.id],
						is_password_protected: payload.is_password_protected
					}
				};
			}
			break;

		default:
			return state;
	}
};
export default chatsReducer;
