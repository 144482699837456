import { TYPE_DELETE_REFERENCE_MESSAGE, TYPE_SET_REFERENCE_MESSAGE } from '../actions/refernce';


const initialState = [];

const referenceMessage = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case TYPE_SET_REFERENCE_MESSAGE:

			return [...payload.reference];

		case TYPE_DELETE_REFERENCE_MESSAGE:

			return [];

		default:
			return state;
	}
};

export default referenceMessage;
