import { setMessageChat } from './message';
import axios from '../../utils/axios';
import { toast } from 'react-toastify';


export const ADD_CHAT = 'ADD_CHAT';

export const ADD_ACCESS_KEY = 'ADD_ACCESS_KEY';

export const CHANGE_IS_HASH = 'CHANGE_IS_HASH';


export const addChatToListChat = (chat) => {
	return dispatch => {
		dispatch({
			type: ADD_CHAT,
			payload: chat
		});
	};
};


export const changePasswordToggle = (chatId, is_password_protected) => {
	return dispatch => {
		dispatch({
			type: CHANGE_IS_HASH,
			payload: { id: chatId, is_password_protected: is_password_protected }
		});
	};
};


export const detectPasswordChat = (taskId, password) => {
	return async dispatch => {
		try {
			const payload = await axios.post(`/api/v1/chatgpt/chat-session`, {
				task_id: taskId,
				password: password
			});

			dispatch({
				type: ADD_ACCESS_KEY,
				payload: {
					id: Number(taskId),
					accessKey: payload.session_token
				}
			});
			if (payload.session_token) {
				await dispatch(setMessageChat(taskId, true, { encrypted_token: payload.session_token }));
			}


			return payload.session_token;

		} catch (e) {

			toast.error(e.error, {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true
			});
			return false;

		} finally {

		}
	};
};


export const addAccessKey = (id, accessKey) => ({
	type: ADD_ACCESS_KEY,
	payload: { id: id, accessKey: accessKey }
});
