import Axios from 'axios';


const http_domain = process.env.REACT_APP_BASE_URL || '';

const axios = Axios.create({
	baseURL: http_domain,
	// withCredentials: true,
	headers: {
		// 'Content-Type': 'application/json',
	}
});

axios.interceptors.request.use(config => {
	if (localStorage.token) {
		config.headers.Authorization = `Token ${localStorage.token}`;
	}
	return config;
});

axios.interceptors.response.use(
	response => {
		return response.data;
	},
	error => {
		if (error.response.status === 401) {
			localStorage.removeItem('token');
		}
		return Promise.reject(error.response.data);
	}
);

export default axios;
