import {
	INCREMENT_COUNT_REGULAR_CHAT,
	TYPE_REMOVE_ALL_TOOLTIP,
	TYPE_REMOVE_TOOLTIP,
	TYPE_SET_FILE_EXIST,
	TYPE_SET_TOOLTIP
} from '../actions';


const initialState = {
	existButton: true,
	type: null,
	countRegularChat: 0,
	tooltips: {}
};

const tipsReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {

		case INCREMENT_COUNT_REGULAR_CHAT:
			return {
				...state,
				countRegularChat: state.countRegularChat + 1,
				type: payload.type
			};

		case TYPE_SET_TOOLTIP:
			// Проверяем, что payload содержит массив и он не пустой
			if (Array.isArray(payload.tooltipKeys) && payload.tooltipKeys.length > 0) {
				const newTooltips = { ...state.tooltips };
				// Устанавливаем каждый ключ из массива в true
				payload.tooltipKeys.forEach(key => {
					newTooltips[key] = true;
				});
				return {
					...state,
					tooltips: newTooltips,
					type: TYPE_SET_TOOLTIP
				};
			}
			return state; // Если условия не выполняются, возвращаем текущее состояние

		case TYPE_REMOVE_TOOLTIP:
			const newState = { ...state };
			delete newState.tooltips[payload.tooltipKey]; // Удаляем ключ подсказки
			return {
				...newState,
				type: TYPE_REMOVE_TOOLTIP
			};
		case  TYPE_SET_FILE_EXIST:
			return {
				...state,
				existButton: payload
			};

		case TYPE_REMOVE_ALL_TOOLTIP:
			return {
				...state,
				tooltips: {}
			};


		default:
			return state;


	}
};

export default tipsReducer;
