import { TYPE_ADD_FAVORITE, TYPE_DELETE_FAVORITE, TYPE_SET_FAVORITE_LIST } from '../actions';


const initialState = {};

const buttonLikeListReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case TYPE_SET_FAVORITE_LIST:
			if (payload.isRefresh) {
				return { ...payload.result };
			}
			return state;

		case TYPE_ADD_FAVORITE:
			return {
				...state,
				[payload.id]: true
			};
		case TYPE_DELETE_FAVORITE:
			return {
				...state,
				[payload.id]: false
			};
		default:
			return state;
	}
};

export default buttonLikeListReducer;
