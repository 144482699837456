import axios from '../../utils/axios';
import { toast } from 'react-toastify';


export const SET_CURRENT_VOICE_TONE = 'SET_CURRENT_VOICE_TONE';

export const CLEAR_CURRENT_VOICE_TONE = 'CLEAR_CURRENT_VOICE_TONE';

export const SET_VOICE_TONE = 'SET_VOICE_TONE';

export const UPDATE_VOICE_TONE_TASK_EXISTS = 'UPDATE_VOICE_TONE_TASK_EXISTS';

export const ADD_VOICE_TONE = 'ADD_VOICE_TONE';
export const RESET_TASK_EXISTS_FOR_VOICE_TONE = 'RESET_TASK_EXISTS_FOR_VOICE_TONE';

export const DELETE_VOICE_TONE_BY_ID = 'DELETE_VOICE_TONE_BY_ID';

export const UPDATE_VOICE_TONE_BY_ID = 'UPDATE_VOICE_TONE_BY_ID';


export const addVoiceTone = data => {
	return async dispatch => {
		try {
			dispatch({
				type: UPDATE_VOICE_TONE_TASK_EXISTS,
				payload: { id: data.prompt_template_id }
			});

			await axios.post(`/api/v1/chatgpt/voice-tone`, data);
			dispatch({
				type: UPDATE_VOICE_TONE_TASK_EXISTS,
				payload: { id: data.prompt_template_id }
			});
		} catch (e) {
			toast.error(`An error has occurred`, {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true
			});
		}
	};
};

export const createVoiceTone = data => {
	return async dispatch => {
		try {

			const payload = await axios.post(`/api/v1/chatgpt/create-voice-tone`, data);
			dispatch({
				type: ADD_VOICE_TONE,
				payload: payload
			});

			dispatch({
				type: SET_CURRENT_VOICE_TONE,
				payload: payload
			});
			dispatch({
				type: UPDATE_VOICE_TONE_TASK_EXISTS,
				payload: { id: payload.id }
			});
		} catch (e) {
			toast.error(`An error has occurred`, {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true
			});
		}
	};
};


export const deleteCurrentVoiceTone = (voiceToneId, data) => {
	return async dispatch => {
		try {

			await axios.put(`/api/v1/chatgpt/voice-tone/${voiceToneId}/delete`, data);
			dispatch({
				type: RESET_TASK_EXISTS_FOR_VOICE_TONE,
				payload: voiceToneId
			});


		} catch (e) {
			toast.error(`An error has occurred`, {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true
			});
		}
	};
};


export const updateVoiceTone = (voiceToneId, data) => {
	return async dispatch => {
		try {

			await axios.put(`/api/v1/chatgpt/voice-tone/${voiceToneId}/update`, data);
			dispatch({
				type: UPDATE_VOICE_TONE_BY_ID,
				payload: { id: voiceToneId, name: data.name, template: data.template }
			});
		} catch (e) {
			toast.error(`An error has occurred`, {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true
			});
		}
	};
};


export const deleteVoiceTone = (voiceToneId) => {
	return async dispatch => {
		try {

			await axios.delete(`/api/v1/chatgpt/voice-tone/${voiceToneId}/delete`);
			dispatch({
				type: DELETE_VOICE_TONE_BY_ID,
				payload: voiceToneId
			});
		} catch (e) {
			toast.error(`An error has occurred`, {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true
			});
		}
	};
};


export const getCurrentVoiceTone = (chatId) => {
	return async dispatch => {
		try {
			const payload = await axios.get(`/api/v1/chatgpt/voice-tone/${chatId}`);

			if (payload?.length > 0) {
				const taskExistsObject = payload?.find(item => item.task_exists === true);
				if (taskExistsObject) {
					dispatch({
						type: SET_CURRENT_VOICE_TONE,
						payload: taskExistsObject
					});
				}
			}

			dispatch({
				type: SET_VOICE_TONE,
				payload: payload
			});
			// dispatch({
			//     type: SET_CURRENT_VOICE_TONE,
			//     payload: payload
			// })
			// return payload.results
		} catch (e) {
			toast.error(`An error has occurred`, {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true

			});
		} finally {


		}
	};
};
