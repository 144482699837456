import axios from '../../utils/axios';


export const TYPE_SET_REFERENCE_MESSAGE = 'TYPE_SET_REFERENCE_MESSAGE';

export const TYPE_DELETE_REFERENCE_MESSAGE = 'TYPE_DELETE_REFERENCE_MESSAGE';


export const getMyReferenceFileContent = (id) => {
	return async dispatch => {
		try {
			dispatch({
				type: TYPE_DELETE_REFERENCE_MESSAGE
			});
			const payload = await axios.get(`/api/v1/chatgpt/reference-content/${id}`);

			dispatch({
				type: TYPE_SET_REFERENCE_MESSAGE,
				payload: {
					reference: payload
				}
			});
		} catch (e) {

		}
	};
};


export const deleteReference = () => {
	return async dispatch => {
		try {
			dispatch({
				type: TYPE_DELETE_REFERENCE_MESSAGE
			});
		} catch (e) {

		}
	};
};
