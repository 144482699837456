export const TYPE_SET_LOADING_MESSAGE = 'TYPE_SET_LOADING_MESSAGE';


export const onSetLoadingMessage = (data) => {
	return dispatch => {
		try {

			dispatch({
				type: TYPE_SET_LOADING_MESSAGE,
				payload: data
			});
		} catch (e) {
			console.log(e);
		}
	};
};
