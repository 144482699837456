import React from 'react';
import {
	Box,
	Button,
	Typography
} from '@mui/material';
import { DocIcon, FileArrowIcon, PdfIcon } from '../../../static/svg';


export default function DriveUploadDocument({
	                                            status,
	                                            uploadFile,
	                                            handleDragOver,
	                                            handleDragLeaveOrDrop,
	                                            handleDrop,
	                                            isDragOver,
	                                            uploadProgress,
	                                            handleFileSelect,
	                                            onCloseModal

                                            }) {

	return (
		<Box>
			{status === 'start' && (<Box
				onDragOver={handleDragOver}
				onDragLeave={handleDragLeaveOrDrop}
				onDrop={handleDrop}
				component="label" // Оборачиваем input в label, чтобы пользователь мог нажать на любую часть Box
				htmlFor="file" // Связываем
				sx={{
					width: '100%',
					borderRadius: '16px',
					overflow: 'hidden',

					border: isDragOver ? '2px dashed #444CC6' : '2px dashed #D0D5DD', // Меняем стиль рамки
					backgroundColor: isDragOver ? '#F1F2FF' : '#F9FAFB', // Меняем цвет фона
					display: 'flex',
					flexDirection: 'column',
					p: 2, pt: 2, pb: 2,
					justifyContent: 'center'
				}}
			>
				<Box sx={{ paddingTop: 4, paddingBottom: 4 }}>

					<>
						<Box sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex', mb: 2.5 }}>
							<FileArrowIcon />
						</Box>
						<Typography sx={{ textAlign: 'center', fontWeight: '500', color: '#101828', fontSize: '16px' }}>Choose
							PDF
							or Doc files to upload.</Typography>
						<Typography sx={{ textAlign: 'center', fontWeight: '400', color: '#98A2B3', fontSize: '14px' }}>Select
							one
							or more or drag and drop them here.</Typography>


					</>


					{!(status === 'upload') && (
						<input
							type="file"
							id="file"
							multiple
							onChange={handleFileSelect}
							accept="application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
							style={{ display: 'none' }} // Скрываем реальный input
						/>)}
				</Box>

			</Box>)}
			{status === 'upload' && (
				<Box sx={{
					background: 'white',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					p: 2,
					borderRadius: '12px',
					mb: 2,
					pt: 3,
					pb: 4
				}}>
					<Box sx={{
						width: '90%',
						height: '10px',
						background: '#EAECF0',
						borderRadius: '5px',
						overflow: 'hidden',
						mb: 2
					}}>
						<Box sx={{
							width: `${uploadProgress}%`,
							height: '100%',
							background: 'linear-gradient(99.97deg, #64ABFF 0%, #444CC6 102.14%)'
						}} />
					</Box>

					<Typography sx={{
						color: '#101828',
						fontSize: '16px',
						fontWeight: '500',
						textAlign: 'center'
					}}>Uploading your document</Typography>
					<Typography sx={{
						textAlign: 'center',
						color: '#98A2B3',
						fontSize: '14px',
						fontWeight: '400'
					}}>{`${uploadProgress}%`}</Typography>

				</Box>
			)

			}

			{
				status === 'finished' && (
					<Box sx={{
						mb: 2
					}}>
						{uploadFile.uploadedFiles.map((item) => {
							return (
								<Box sx={{
									position: 'relative', display: 'flex',
									background: '#F2F4F7'
									, p: 2,
									border: '1px solid #EAECF0',
									borderRadius: '12px'
								}}>
									<Box sx={{
										background: item.type === '.pdf' ? '#912018' : '#0A7CFF',
										p: 1,
										height: '70px',
										borderRadius: '9px'
									}}>
										{item.type === '.pdf' && <PdfIcon />}
										{item.type === '.docx' && <DocIcon />}
									</Box>
									<Box sx={{ ml: 1, width: '90%' }}>
										<Typography sx={{
											fontWeight: '500',
											color: '#101828',
											fontSize: 17,
											display: '-webkit-box',
											WebkitLineClamp: 2,
											WebkitBoxOrient: 'vertical',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											wordWrap: 'break-word',
											width: '90%'
										}}>{item.name}</Typography>
									</Box>

								</Box>
							);
						})}
						<Button sx={{ border: '1px solid #D0D5DD', borderRadius: '8px', mt: 3, pt: 0.9, pb: 0.9 }}
						        onClick={onCloseModal}>
							<Typography sx={{ color: '#444CC6', fontWeight: '500', fontSize: 16 }}>Close</Typography>
						</Button>
					</Box>
				)
			}
		</Box>

	);

}
