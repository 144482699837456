import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router';


function useHotjar(title) {
	const location = useLocation();

	useEffect(() => {
		ReactGA.send({
			hitType: 'pageview',
			page: location.pathname,
			title: title || document.title
		});

		if (window.hj) {
			window.hj('stateChange', window.location.href);
		}
	}, [location, title]);
}

export default useHotjar;
