import {
	TYPE_ADD_MESSAGE,
	TYPE_ADD_PARTICIPANT_TO_CURRENT_TASK,
	TYPE_ADD_REPLY_MESSAGE,
	TYPE_ADD_REPLY_TEXT,
	TYPE_CLEAR_MESSAGE,
	TYPE_DELETE_MESSAGE,
	TYPE_DELETE_REPLY_MESSAGE,
	TYPE_DELETE_REPLY_TEXT,
	TYPE_INCREMENT_EMOTION_COUNT,
	TYPE_PREPEND_CHAT_MESSAGE,
	TYPE_SET_CHAT_MESSAGE,
	TYPE_SET_CURRENT_CHAT,
	TYPE_SET_IS_LOADING,
	TYPE_TOGGLE_CONNECT,
	TYPE_TOGGLE_IS_NEXT_LOADING,
	TYPE_TOGGLE_TYPING
} from '../actions';


const initialState = {
	replyMessage: {},
	replyText: {},
	currentChatInfo: {},
	isLoading: true,
	isNextLoading: false,
	nextLink: null,
	messages: [],
	typing: false,
	connect: false
};

const messageReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {

		case TYPE_SET_CHAT_MESSAGE:
			const updatedMessages = payload.isRefresh ? payload.messages.reverse() :
				[...payload.messages.reverse(), ...state.messages];
			return {
				...state,
				messages: updatedMessages,
				nextLink: payload.next || null, // Обновляем ссылку на следующую страницу, если она есть в payload
				isLoading: false // Предполагаем, что загрузка закончена после получения сообщений
			};
		// if(payload.isRefresh){
		//    return {...state, messages: payload.messages.reverse()};
		// }
		// return  {...state, messages: [...payload.messages.reverse(), ...state.messages]};


		case  TYPE_CLEAR_MESSAGE:
			return { ...state, messages: [] };

		case TYPE_DELETE_MESSAGE:
			// Удаление из replyText, если есть
			const newReplyText = { ...state.replyText };

			if (newReplyText[payload.chat] && newReplyText[payload.chat].message_number === payload.id) {
				delete newReplyText[payload.chat];
			}

			// Удаление из массива messages
			const newMessages = state.messages.filter(message => message.message_number !== payload.id);

			return {
				...state,
				replyText: newReplyText,
				messages: newMessages
			};


		case TYPE_SET_CURRENT_CHAT:
			return { ...state, currentChatInfo: payload.chat };


		case TYPE_ADD_PARTICIPANT_TO_CURRENT_TASK:
			const participants = state.currentChatInfo?.participants || [];
			let updatedParticipants;
			if (participants.length === 1) {
				updatedParticipants = [payload, ...participants];
			} else if (participants.length === 2) {
				updatedParticipants = [payload, ...participants.slice(0, 2)];
			} else {
				updatedParticipants = [...participants, payload];
			}

			return {
				...state,
				currentChatInfo: {
					...state.currentChatInfo,
					participants: updatedParticipants
				}
			};

		case TYPE_INCREMENT_EMOTION_COUNT:
			const emotionUpdatedMessages = state.messages.map(message => {
				if (message.id === payload.messageId) {
					// Initialize emotions as an empty array if undefined
					message.emotions = message.emotions || [];

					let isAlreadyPressed = false;

					// Now we can safely use forEach
					message.emotions.forEach(emotion => {
						if (emotion.type === payload.emotionType && emotion.isPressed) {
							isAlreadyPressed = true;
						}
					});

					if (isAlreadyPressed) {
						return message;
					} else {
						return {
							...message,
							emotions: message.emotions.map(emotion => {
								if (emotion.type === payload.emotionType) {
									return {
										...emotion,
										count: emotion.count + 1,
										isPressed: true
									};
								}
								return { ...emotion, isPressed: false };
							})
						};
					}
				}
				return message;
			});

			return {
				...state,
				messages: emotionUpdatedMessages
			};

		case TYPE_PREPEND_CHAT_MESSAGE:
			return { ...state, messages: [...state.messages, payload.message] };

		case TYPE_ADD_MESSAGE:
			return { ...state, messages: [...state.messages, ...payload.messages] };


		case TYPE_TOGGLE_TYPING:
			return { ...state, typing: payload.status }; // payload должен содержать статус true или false

		case TYPE_TOGGLE_IS_NEXT_LOADING:
			return { ...state, isNextLoading: payload.status }; // payload должен содержать статус true или false

		case TYPE_SET_IS_LOADING:
			return { ...state, isLoading: payload.isLoading };


		case TYPE_TOGGLE_CONNECT:
			return { ...state, connect: payload.status };


		case TYPE_ADD_REPLY_MESSAGE:
			return {
				...state,
				replyMessage: {
					...state.replyMessage,
					[payload.key]: payload.value
				}
			};

		case TYPE_DELETE_REPLY_MESSAGE:
			const updatedReplyMessage = { ...state.replyMessage };
			if (payload.key in updatedReplyMessage) {
				delete updatedReplyMessage[payload.key];
			}
			return {
				...state,
				replyMessage: updatedReplyMessage
			};

		case TYPE_ADD_REPLY_TEXT:
			return {
				...state,
				replyText: {
					...state.replyText,
					[payload.key]: payload.value
				}
			};

		case TYPE_DELETE_REPLY_TEXT:
			const updatedReplyText = { ...state.replyText };
			if (payload.key in updatedReplyText) {
				delete updatedReplyText[payload.key];
			}
			return {
				...state,
				replyText: updatedReplyText
			};
		default:
			return state;
	}
};


export default messageReducer;
