import axios from '../../utils/axios';
import { signin, signOutFunc } from '../../utils/auth';
import { toast } from 'react-toastify';


export const TYPE_SET_PROFILE_SUCCESS = 'TYPE_SET_PROFILE_SUCCESS';

export const TYPE_CHANGE_PHOTO_SUCCESS = 'TYPE_CHANGE_PHOTO_SUCCESS';

export const TYPE_UPDATE_USER_SUCCESS = 'TYPE_UPDATE_USER_SUCCESS';

export const TYPE_UPDATE_USER_ERROR = 'TYPE_UPDATE_USER_ERROR';

export const TYPE_UPDATE_ACCESS_STATUS = 'TYPE_UPDATE_ACCESS_STATUS';

export const TYPE_START_UPDATE_USER = 'TYPE_START_UPDATE_USER';

export const TYPE_FINISHED_UPDATE_USER = 'TYPE_FINISHED_UPDATE_USER';

export const TYPE_START_UPDATE_AVATAR = 'TYPE_START_UPDATE_AVATAR';

export const TYPE_FINISHED_UPDATE_AVATAR = 'TYPE_FINISHED_UPDATE_AVATAR';

export const TYPE_CLEAR_DISCOUNT_INFO = 'TYPE_CLEAR_DISCOUNT_INFO';


export const auth = form => {
	return async dispatch => {
		try {
			const payload = await axios.post('/api/v1/users/sign-in', form);
			signin(payload);
		} catch (error) {
			return error;
		}
	};
};


export const register = form => {
	return async dispatch => {
		try {
			const payload = await axios.post('/api/v1/users/register', form);
			signin(payload);
			return payload;
		} catch (error) {
			return error;
		}
		// return await dispatch(getProfile());
	};
};

export const resendCode = form => {
	return async dispatch => {
		const payload = await axios.post('/api/v1/users/resend-code', form);
		return payload;
		// return await dispatch(getProfile());
	};
};


export const confirm = form => {
	return async dispatch => {
		const payload = await axios.post('/api/v1/users/confirm', form);
		return payload;
		// return await dispatch(getProfile());
	};
};

export const confirmCode = form => {
	return async dispatch => {
		try {
			return await axios.post('/api/v1/users/confirm-code', form);
		} catch (e) {
			toast.error(`Incorrect code or token lifetime has finally disappeared`, {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true
			});
		}

		// return await dispatch(getProfile());
	};
};

export const signOut = form => {
	return async dispatch => {
		await axios.delete('/api/v1/users/signout', form);
		signOutFunc();
	};
};


export const getUser = () => {
	return async dispatch => {
		try {
			const user = await axios.get('/api/v1/users/user');
			dispatch({ type: TYPE_SET_PROFILE_SUCCESS, payload: user });
			return user;
		} catch (e) {
			toast.error(`An error has occurred`, {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true
			});
		}


	};

};

export const changePassword = (data) => {
	return async dispatch => {
		try {
			await axios.put('/api/v1/users/change-password', data);
			toast.success('Your Password has been Changed Successfully', {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true
			});

		} catch (e) {
			return e;
		}
	};
};


export const changeProfilePicture = (file) => {
	return async dispatch => {
		dispatch({
			type: TYPE_START_UPDATE_AVATAR
		});
		const formData = new FormData();
		formData.append('image', file);

		try {
			const response = await axios.put('/api/v1/users/change-image', formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});
			dispatch({
				type: TYPE_CHANGE_PHOTO_SUCCESS,
				payload: response.image
			});
			// Вы можете добавить здесь обработку успешной отправки, например dispatch({ type: 'CHANGE_PICTURE_SUCCESS' })
		} catch (error) {
			// Обработайте здесь ошибку, например dispatch({ type: 'CHANGE_PICTURE_FAIL', payload: error.response.data })
		} finally {
			dispatch({
				type: TYPE_FINISHED_UPDATE_AVATAR
			});
		}
	};
};


export const updateUser = (data) => {
	return async dispatch => {
		dispatch({
			type: TYPE_START_UPDATE_USER
		});
		try {
			const response = await axios.put('/api/v1/users/update-user', data);
			dispatch({
				type: TYPE_UPDATE_USER_SUCCESS,
				payload: response
			});
			toast.success('Changes saved Successfully', {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true
			});


		} catch (error) {
			dispatch({
				type: TYPE_FINISHED_UPDATE_USER
			});
			return error;
		} finally {
			dispatch({
				type: TYPE_FINISHED_UPDATE_USER
			});

		}

	};
};


export const deleteAccount = () => {
	return async dispatch => {
		await axios.delete('/api/v1/users/delete-user');
		signOutFunc();
	};
};


export const authSocialGoogle = (token, invitation_task) => {
	return async dispatch => {
		try {
			const data = {
				access_token: token
			};
			if (invitation_task) {
				data['invitation_task'] = invitation_task;
			}
			const payload = await axios.post('/auth/google/login/', data);
			signin(payload);
			return payload;
		} catch (error) {
			return error;
		}

		// return await dispatch(getProfile());
	};
};


export const forgotPasswordSendCode = form => {
	return async dispatch => {
		try {
			await axios.post('/api/v1/users/forgot-password-code', form);
		} catch (e) {
			return e;
		}

		// return await dispatch(getProfile());
	};
};


export const confirmForgotPasswordCode = form => {
	return async dispatch => {
		try {
			const response = await axios.post('/api/v1/users/confirm-forgot-password', form);
			return response;
		} catch (e) {
			toast.error(`The code is incorrect or the code has expired`, {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true

			});
			return false;
		}

		// return await dispatch(getProfile());
	};
};

export const resetPassword = form => {
	return async dispatch => {
		try {
			const response = await axios.post('/api/v1/users/reset-password', form);
			return response;
		} catch (e) {
			toast.error(`The token has expired, the form is again`, {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true

			});
			return false;
		}

		// return await dispatch(getProfile());
	};
};


export const resendCodeForgotPassword = form => {
	return async dispatch => {
		const payload = await axios.post('/api/v1/users/resend-code-forgot-password', form);
		return payload;
		// return await dispatch(getProfile());
	};
};


export const clearDiscountInfo = () => {
	return async dispatch => {
		dispatch({
			type: TYPE_CLEAR_DISCOUNT_INFO
		});
	};
};


export const downloadTerms = () => {
	return async dispatch => {
		try {
			const response = await axios.get(
				`/api/v1/chatgpt/terms`,
				{ responseType: 'blob' }
			);
			const blob = new Blob([response], { type: 'application/pdf' });

			const link = document.createElement('a');
			link.href = URL.createObjectURL(blob);
			link.download = 'Terms_and_Conditions.pdf';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);

		} catch (e) {
			toast.error(`An error has occurred`, {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true

			});
		}
	};
};


export const updateAccessUser = isAccess => {
	return async dispatch => {
		try {
			await axios.post('/api/v1/payment/update-access', { has_access: isAccess });

			dispatch({
				type: TYPE_UPDATE_ACCESS_STATUS,
				payload: { hasAccess: isAccess }
			});

		} catch (e) {
			toast.error(`An error has occurred`, {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true

			});
		}

		// return await dispatch(getProfile());
	};
};

