/* eslint-disable */
import { Box, CircularProgress, IconButton, Tooltip, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { pdfjs } from 'react-pdf';
import { useLocation } from 'react-router';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../store/actions';


pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

function highlightPattern(text, patterns) {
	patterns.forEach(pattern => {
		text = text.replace(new RegExp(pattern, 'gi'), (match) => `<span style="background: yellow">${match}</span>`);
	});
	return text;
}


const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '100%', // начнем с 80% для мобильных устройств
	maxWidth: '660px',
	bgcolor: 'background.paper',

	// Медиа-запросы
	'@media (min-width:600px)': { // для устройств с экраном от 600px и выше
		width: 700 // фиксированная ширина 600px для больших экранов
	},
	'@media (max-width:599px)': { // для устройств с экраном до 599px
		width: 700 // ширина 90% для меньших экранов
	},
	display: 'flex',          // <-- Add these
	alignItems: 'center',     // <-- Add these
	justifyContent: 'center'  // <-- Add these,

};


export default function PreviewPdf({ file, setPreviewModal, onClose, content, initialPage }) {
	const referenceMessage = useSelector(state => state.referenceMessage);
	const [numPages, setNumPages] = useState(null);
	const location = useLocation();
	const [loading, setLoading] = useState(true);

	const [searchTexts, _] = useState([]);

	const textRenderer = useCallback(
		(textItem) => {
			return highlightPattern(textItem.str, searchTexts);
		},
		[searchTexts]
	);
	const dispatch = useDispatch();

	async function getReference() {
		await dispatch(actions.getMyReferenceFileContent(content));

	}

	useEffect(() => {
		getReference();
	}, [content]);

	const searchParams = new URLSearchParams(location.search);

	// const initialPage = parseInt(searchParams.get('page'), 10) || 0


	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages);
		setLoading(false);

	}


	const createHighlightStyle = (x, y, width, height) => {
		return {
			position: 'absolute',
			top: `${y}%`,
			left: `${x}%`,
			width: `${width}%`,
			height: `${height}%`,
			backgroundColor: 'rgba(255, 226, 143, 1)',
			opacity: 0.3,
			zIndex: 0
		};
	};

	return (
		<Box sx={{
			display: 'flex',
			flexDirection: 'row'
		}}>
			<Box sx={style}>
				{referenceMessage.length > 0 && (<Box sx={{
					background: 'white',
					position: 'absolute',
					zIndex: 99999,
					width: '25%',
					left: -150,
					height: '800px',
					overflowY: 'auto' // Добавляем скролл при необходимости
					// Граница для всего контейнера
				}}>
					{referenceMessage.map((item, index) => (
						<Tooltip title={item.text}>
							<Box key={index} sx={{
								height: '100px',
								borderBottom: '1px solid #EAECF0',
								p: 1
							}}>
								<Typography sx={{
									display: '-webkit-box',
									overflow: 'hidden',
									WebkitLineClamp: 3,
									WebkitBoxOrient: 'vertical',
									textOverflow: 'ellipsis'
								}}>{item.text}</Typography>
								<Typography style={{ textAlign: 'right', fontSize: 10 }}>Page: {item.page}</Typography>
							</Box>
						</Tooltip>
					))}
				</Box>)}
				<IconButton
					onClick={() => {
						onClose();
					}}
					sx={{
						position: 'absolute',
						top: 10,
						right: 10,
						color: 'black',
						zIndex: 9999
					}}
				>
					<CloseIcon />
				</IconButton>

				{loading && (
					<CircularProgress
						size={48}
						sx={{
							color: '#64ABFF',
							position: 'absolute',
							zIndex: 1000,
							animation: `
              @keyframes fadeIn {
                0% { opacity: 0; }
                100% { opacity: 1; }
              }
              fadeIn 0.5s ease-in-out both
            `
						}}
					/>
				)}

				<div
					style={{
						height: '800px',
						width: '660px',
						overflowY: 'scroll',
						overflowX: 'scroll',
						position: 'relative'
					}}
				>
					<Document
						loading={null}
						// className={"PDFDocument"}
						file={file}
						onLoadSuccess={onDocumentLoadSuccess}
					>
						{Array.from({ length: numPages }, (_, index) => (
							<div key={`page_${index + 1}`} style={{ position: 'relative' }}>
								<Page pageNumber={index + 1}
								      renderTextLayer={true}
								      customTextRenderer={textRenderer}
								      inputRef={(ref) => {
									      if (ref && initialPage === index + 1) {
										      ref.scrollIntoView({ behavior: 'smooth', block: 'start' });
									      }
								      }} />
								{!loading && (<div>
									{index + 1 === initialPage &&
										<div style={createHighlightStyle(0, 0, 100, 100)}></div>}
								</div>)}
							</div>
						))}
					</Document>
				</div>
			</Box>
		</Box>
	);
}
