import { stringToSHA1 } from './string-to-sha1';


export async function trackOrder({ email, price_id, id }) {
	if (window.ire) {
		const hashedEmail = email ? await stringToSHA1(email) : '';


		window.ire('trackConversion', 45399, {
				orderId: price_id,
				customerId: id,
				customerEmail: hashedEmail,
				customProfileId: ''
			},
			{
				verifySiteDefinitionMatch: true
			}
		);

	}
}
