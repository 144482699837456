import { TYPE_SET_CONTENT_FILE } from '../actions/contentFile';


const initialState = {};

const contentFile = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case TYPE_SET_CONTENT_FILE:
			return { ...payload };
		default:
			return state;
	}
};

export default contentFile;
