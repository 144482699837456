import { combineReducers } from 'redux';
import task from './task';
import button from './button';
import drive from './drive';
import payment from './payment';
import uploadFile from './uploadFile';
import recentlyTask from './recently_task';
import currentTask from './currentTask';
import buttonLikeList from './buttonLikeList';
import message from './message';
import taskStore from './taskStore';
import user from './user';
import draft from './draft';
import selectedMessage from './selectedMessage';
import notification from './notification';
import typingMessage from './typingMessage';
import taskParticipation from './taskParticipation';
import reference from './reference';
import voiceTone from './voiceTone';
import emojiMessage from './emojiMessage';
import typeTyping from './typeTyping';
import contentFile from './contentFile';
import referenceMessage from './refernce_message';
import chatsReducer from './chat';
import tips from './tips';


const appReducer = combineReducers({
	task,
	button,
	drive,
	payment,
	uploadFile,
	recentlyTask,
	currentTask,
	buttonLikeList,
	message,
	taskStore,
	user,
	draft,
	notification,
	selectedMessage,
	typingMessage,
	voiceTone,
	taskParticipation,
	reference,
	emojiMessage,
	typeTyping,
	contentFile,
	referenceMessage,
	chatsReducer,
	tips
});


export default appReducer;
