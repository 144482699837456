import React, { useEffect, useState } from 'react';
import AuthLayout from '../AuthLayout';
import {
	Box,
	Checkbox,
	CircularProgress,
	FormControlLabel,
	IconButton,
	InputAdornment,
	OutlinedInput,
	styled,
	Typography,
	useTheme
} from '@mui/material';
import Button from '@mui/material/Button';
import { ErrorInfo, EyeIcon, NextLogInIcon } from '../../../static/svg';
import { useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as actions from '../../../store/actions';
import { connect, useDispatch } from 'react-redux';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { Field, Form, Formik, useFormik } from 'formik';
import * as Yup from 'yup';
import { GoogleLogin } from '@react-oauth/google';
import { toast } from 'react-toastify';
import InputMask from 'react-input-mask';
import useHotjar from '../../../hooks/hotjar';
import { getCountryCallingCode } from 'libphonenumber-js';
import { useLocation } from 'react-router';
import { trackOrder } from '../../../utils/impact-com';
import { usePromoCode } from './usePromoCode';


const StyledPhoneInput = styled(PhoneInput)({
	'& input': {
		border: 'none',
		outline: 'none',
		boxShadow: 'none',
		background: 'transparent',
		fontSize: 14,
		width: '100%',
		'&::placeholder': {
			color: 'desiredColor' // замените на желаемый цвет
		}
	}
});

const validationSchema1 = Yup.object().shape({
	code: Yup.string()
		.required('Code is required')
		.length(6, 'Code must be exactly 6 characters')
});

const CodeInput = ({ setStatus, setToken, email }) => {
	const [timeLeft, setTimeLeft] = useState(120); // 120 seconds = 2 minutes
	const [codeSent, setCodeSent] = useState(true);
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [resendloading, setResendLoading] = useState(false);
	const theme = useTheme();

	const formik = useFormik({
		initialValues: {
			code: ''
		},
		validationSchema1,
		onSubmit: async (values) => {
			setLoading(true);
			const token = await dispatch(actions.confirmCode({ confirmation_code: values.code }));
			if (token) {
				setToken(token?.token);
				setStatus('password');
			}
			setLoading(false);

		}
	});

	useEffect(() => {
		if (!codeSent) return;

		// decrement time left by one every second
		const interval = setInterval(() => {
			setTimeLeft((timeLeft) => timeLeft - 1);
		}, 1000);

		// clear interval on component unmount
		return () => clearInterval(interval);
	}, [codeSent]);

	const resendCode = async () => {
		setResendLoading(true);
		try {

			await dispatch(actions.resendCode({ email: email }));
			setCodeSent(true);
			setTimeLeft(120);
		} catch (e) {

		} finally {
			setResendLoading(false);
		}

		// Implement your resend code logic here
	};
	return (
		<form onSubmit={formik.handleSubmit}>
			<InputMask name={'code'} mask="999999" placeholder="Enter 6-digit code" onChange={formik.handleChange}
			           value={formik.values.code}>
				{(inputProps) => (
					<OutlinedInput

						{...inputProps}
						margin="normal"
						required
						fullWidth
						error={formik.touched.code && Boolean(formik.errors.code)}
						helperText={formik.touched.code && formik.errors.code}
						variant="standard"
						id="code"
						label="code"
						name={'code'}
						sx={{
							'& .MuiOutlinedInput-notchedOutline': {
								borderWidth: 0 // Setting border-width to 0 will remove the border
							},
							'& fieldset': { border: 'none' }
						}}
					/>
				)}
			</InputMask>
			<Box sx={{
				display: 'flex', alignItems: 'center', mt: 3, [theme.breakpoints.down('sm')]: {
					flexDirection: 'column'
				}
			}}>

				<Button
					disabled={!formik.isValid || formik.isSubmitting || loading}
					type="submit"
					endIcon={!loading && <NextLogInIcon />}
					// fullWidth
					variant="contained"

					sx={{
						background: 'linear-gradient(99.55deg, #276CFF 42.8%, #4A84FF 81.65%)',
						p: 3, borderRadius: 999,
						pt: 1,
						pb: 1, mr: 2,
						color: 'white'
					}}
				>
					{loading ? <CircularProgress size={22} /> : <Typography sx={{
						color: 'white', fontWeight: '500',
						[theme.breakpoints.down('sm')]: {
							fontSize: '12px'
						}
					}}>Confirm Code</Typography>}
				</Button>

				{codeSent && timeLeft > 0 ? (
					<Typography sx={{
						[theme.breakpoints.down('sm')]: {
							mt: 2
						}
					}}>
						Resend code in {Math.floor(timeLeft / 60)}:{timeLeft % 60 < 10 ? '0' : ''}{timeLeft % 60}
					</Typography>
				) : (
					<Button onClick={resendCode} disabled={resendloading} sx={{
						[theme.breakpoints.down('sm')]: {
							mt: 2
						}
					}}>
						{resendloading ?
							<CircularProgress size={22}
							                  sx={{ color: 'linear-gradient(99.55deg, #276CFF 42.8%, #4A84FF 81.65%)' }} />
							: 'Resend Code'}
					</Button>
				)}
			</Box>
		</form>
	);
};

const validationSchema = Yup.object().shape({
	full_name: Yup.string()
		.min(2, 'Too Short!')
		.max(50, 'Too Long!')
		.required('Required'),
	email: Yup.string()
		.email('Invalid email')
		.required('Required'),
	phone: Yup.string()
		.required('Required')
	// здесь вы можете добавить дополнительные условия валидации для телефона
});


const validationSchema2 = Yup.object().shape({
	password: Yup.string()
		.required('Password is required')
		.min(8, 'Password should be at least 8 characters'),
	password1: Yup.string()
		.oneOf([Yup.ref('password'), null], 'Passwords must match')
		.required('Confirm password is required')
	// ... другие поля ...
});

function Register({ register, confirm, authSocialGoogle }) {
	const history = useNavigate();
	const [googleAuth, setGoogleAuth] = useState(false);

	const [status, setStatus] = useState('register');
	const [email, setEmail] = useState('');
	const [token, setToken] = useState('');
	const [loading, setLoading] = useState(false);
	const [createUserLoading, setCreateUserLoading] = useState(false);
	const [invitationToken, setInvitationToken] = useState(null);
	const { PromoCodeComponent, promoCode } = usePromoCode();


	const location = useLocation();

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const token = queryParams.get('invitation_task');
		if (token) {
			setInvitationToken(token);
		}
	}, [location.search]);


	const [showPassword, setShowPassword] = useState(false);
	const [showPassword2, setShowPassword1] = useState(false);

	const handleTogglePassword = () => {
		setShowPassword((prevShowPassword) => !prevShowPassword);
	};

	const handleTogglePassword2 = () => {
		setShowPassword1((prevShowPassword) => !prevShowPassword);
	};

	const [checked, setChecked] = React.useState(true);

	const handleCheck = (event) => {
		setChecked(event.target.checked);
	};

	const theme = useTheme();


	const [phone, setPhone] = useState('');

	useHotjar();


	const handleCountryChange = (country) => {
		if (typeof country === 'undefined') {
			return;
		}
		const newCountryCode = '+' + getCountryCallingCode(country);
		if (!phone.startsWith(newCountryCode)) {
			setPhone(newCountryCode + phone.replace(/\D/g, ''));
		}
	};


	const onSubmit = async (values, { setErrors }) => {
		if (!checked) {
			toast.error(`You must accept the Terms & Conditions to continue.`, {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true

			});
			return;
		}
		setLoading(true);
		try {
			setEmail(values.email);

			let confirmObject = {
				email: values.email,
				type: 'client',
				language: 'en',
				full_name: values.full_name,
				phone: values.phone
			};

			if (promoCode) {
				confirmObject.promo_code = promoCode;
			}

			await confirm(confirmObject);
			setStatus('code');
		} catch (error) {
			if (error['user']) {
				setErrors({ 'email': error?.user[0] });
			}
		} finally {
			setLoading(false);
		}
	};

	const onCreateUser = async (values) => {
		setCreateUserLoading(true);
		try {
			const data = {
				token: token,
				password: values.password
			};
			if (invitationToken) {
				data['invitation_task'] = invitationToken;
			}

			const { user, task_id } = await register(data);
			await trackOrder({ price_id: user.id, email: user.username, id: user.id });

			history(`/tasks/messenger/${task_id}`);
		} catch (e) {
			console.log(e);
		} finally {
			setCreateUserLoading(false);
		}
	};

	return (
		<AuthLayout>
			<Box
				sx={{
					my: 4,
					mx: 8,
					alignItems: 'center',
					[theme.breakpoints.down('sm')]: {
						// Меньший размер шрифта для мобильных устройств
						my: 2,
						mx: 4
					}

				}}
			>

				{status === 'password' && (
					<>
						<Box sx={{
							display: 'flex',
							justifyContent: 'flex-end',
							pb: 8
						}}>

							<Button sx={{
								background: 'linear-gradient(99.55deg, #276CFF 42.8%, #4A84FF 81.65%)',
								borderRadius: 990,
								pl: 2.5,
								pr: 2.5,
								pt: 0.7,
								pb: 0.7

							}} onClick={() => history('/')}>
								<Typography sx={{ color: 'white', fontWeight: '600' }}>Login</Typography>
							</Button>
						</Box>

						<Typography component="h1" variant="h1">
							Register Account
						</Typography>
						<Typography sx={{ color: '#676D79', pb: 1.5, pt: 0.5, display: 'flex' }}>
							Please set a password for your account
						</Typography>

						<Formik
							initialValues={{
								password: '',
								password1: ''
							}}
							validationSchema={validationSchema2}
							onSubmit={onCreateUser}
						>
							{({ touched, errors, isSubmitting }) => (
								<Form>
									{/* Поле создания пароля */}
									<Box sx={{ mt: 2 }}>
										<Typography sx={{ fontSize: 13, mb: 1 }}>Create Password</Typography>
										<Field
											as={OutlinedInput}
											name="password"
											fullWidth
											type={showPassword ? 'text' : 'password'}
											placeholder="Enter your password"
											endAdornment={
												<InputAdornment position="end">
													<IconButton onClick={handleTogglePassword} edge="end">
														<EyeIcon />
														{/*{showPassword ? <Visibility /> : <VisibilityOff />}*/}
													</IconButton>
												</InputAdornment>
											}
											sx={{
												'& .MuiOutlinedInput-notchedOutline': {
													borderWidth: 0 // Setting border-width to 0 will remove the border
												},
												'& fieldset': { border: 'none' }
											}}
										/>
										{touched.password && errors.password ? (
											<Typography sx={{
												color: '#912018',
												mr: 2,
												fontSize: '15px'
											}}>{errors.password}</Typography>
										) : null}
									</Box>

									{/* Поле подтверждения пароля */}
									<Box sx={{ mt: 2 }}>
										<Typography sx={{ fontSize: 13, mb: 1 }}>Confirm Password</Typography>
										<Field
											as={OutlinedInput}
											name="password1"
											fullWidth
											type={showPassword2 ? 'text' : 'password'}
											placeholder="Re-enter your password"
											endAdornment={
												<InputAdornment position="end">
													<IconButton onClick={handleTogglePassword2} edge="end">
														<EyeIcon />
														{/*{showPassword ? <Visibility /> : <VisibilityOff />}*/}
													</IconButton>
												</InputAdornment>
											}
											sx={{
												'& .MuiOutlinedInput-notchedOutline': {
													borderWidth: 0 // Setting border-width to 0 will remove the border
												},
												'& fieldset': { border: 'none' }
											}}
										/>
										{touched.password1 && errors.password1 ? (
											<Typography sx={{
												color: '#912018',
												mr: 2,
												fontSize: '15px'
											}}>{errors.password1}</Typography>
										) : null}
									</Box>

									{/* Кнопка отправки */}
									<Button
										disabled={isSubmitting || createUserLoading}
										type="submit"
										endIcon={!createUserLoading && <NextLogInIcon />}
										// fullWidth
										variant="contained"

										sx={{
											background: 'linear-gradient(99.55deg, #276CFF 42.8%, #4A84FF 81.65%)',
											p: 3, borderRadius: 999, pt: 1, pb: 1, mr: 2, mt: 2.5
										}}
									>
										{createUserLoading ? <CircularProgress size={22} sx={{ color: 'white' }} /> :
											<Typography sx={{
												fontWeight: '500'
											}}>Create Account</Typography>}
									</Button>
								</Form>
							)}
						</Formik>
					</>
				)}

				{status === 'code' && (
					<>
						<Box sx={{
							display: 'flex',
							justifyContent: 'flex-end',
							pb: 8
						}}>
							<Button sx={{
								borderRadius: 990,
								pl: 2.5,
								pr: 2.5,
								pt: 0.7,
								pb: 0.7, mr: 2

							}} onClick={() => setStatus('register')}>
								<Typography>Back</Typography>
							</Button>
							<Button sx={{
								background: 'linear-gradient(99.55deg, #276CFF 42.8%, #4A84FF 81.65%)',
								borderRadius: 990,
								pl: 2.5,
								pr: 2.5,
								pt: 0.7,
								pb: 0.7

							}} onClick={() => history('/')}>
								<Typography sx={{ color: 'white', fontWeight: '600' }}>Login</Typography>
							</Button>
						</Box>

						<Typography component="h1" variant="h1"
						            sx={{
							            [theme.breakpoints.down('sm')]: {
								            // Меньший размер шрифта для мобильных устройств
								            fontSize: '1.5rem',
								            textAlign: 'center'
							            }
						            }}>
							Confirm Code
						</Typography>
						<Typography sx={{
							color: '#676D79', pb: 1.5, pt: 0.5, display: 'flex',
							[theme.breakpoints.down('sm')]: {
								// Меньший размер шрифта для мобильных устройств
								fontSize: '0rem',
								justifyContent: 'center'
							}
						}}>
							Please confirm the code we sent to <Typography sx={{
							ml: 1, fontWeight: '600'

						}}>{email}</Typography>
						</Typography>

						<CodeInput setToken={setToken} setStatus={setStatus} email={email} />
					</>
				)}

				{status === 'register' && (<>

					<Box sx={{
						display: 'flex',
						justifyContent: 'flex-end',
						pb: 8
					}}>
						<Button sx={{
							background: 'linear-gradient(99.55deg, #276CFF 42.8%, #4A84FF 81.65%)',
							borderRadius: 990,
							pl: 2.5,
							pr: 2.5,
							pt: 0.7,
							pb: 0.7

						}} onClick={() => history('/')}>
							<Typography sx={{ color: 'white', fontWeight: '600' }}>Login</Typography>
						</Button>
					</Box>

					<Typography component="h1" variant="h1">
						Get Started
					</Typography>
					<Typography sx={{ color: '#676D79', pb: 1.5, pt: 0.5 }}>
						Enter your details to create an account
					</Typography>

					<Formik initialValues={{ email: '', full_name: '', phone: '' }}
					        validationSchema={validationSchema}
					        onSubmit={onSubmit}>
						{({ setFieldValue, values, errors, touched }) => (
							<Form>
								<Box>
									<Typography sx={{ fontSize: 13, mb: 1 }}>Full Name</Typography>
									<Field name="full_name" as={OutlinedInput}
									       margin="normal"
									       required
									       fullWidth
									       variant="standard"
									       id="full_name"
									       label="Full Name"
									       placeholder="Enter your full name"
									       sx={{
										       '& .MuiOutlinedInput-notchedOutline': {
											       borderWidth: 0 // Setting border-width to 0 will remove the border
										       },
										       '& fieldset': { border: 'none' }
									       }}
									/>
									{touched.full_name && errors.full_name ? (
										<Box sx={{
											display: 'flex', alignItems: 'center',
											background: '#FEE4E2',
											mt: 1.5,
											pt: 0.5,
											pb: 0.5,
											pl: 0.5,
											borderRadius: '9px',
											pr: 0.5
										}}>
											<Box sx={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
												mr: 1
											}}>
												<ErrorInfo />
											</Box>
											<Typography sx={{
												color: '#912018',
												mr: 2,
												fontSize: '15px'
											}}>{errors.full_name}</Typography>
										</Box>
									) : null}
								</Box>
								<Box sx={{ mt: 2 }}>
									<Typography sx={{ fontSize: 13, mb: 1 }}>Email</Typography>
									<Field name="email" as={OutlinedInput}
									       margin="normal"
									       required
									       fullWidth
									       variant="standard"
									       id="email"
										//     sx={{
										//   "& fieldset": { border: 'none' },
										// }}
										   label="Email"
										// name="email"
										   placeholder="Enter your email"
										   sx={{
											   '& .MuiOutlinedInput-notchedOutline': {
												   borderWidth: 0 // Setting border-width to 0 will remove the border
											   },
											   '& fieldset': { border: 'none' }
										   }}
									/>
									{touched.email && errors.email ? (
										<Box sx={{
											display: 'flex', alignItems: 'center',
											background: '#FEE4E2',
											mt: 1.5,
											pt: 0.5,
											pb: 0.5,
											pl: 0.5,
											borderRadius: '9px',
											pr: 0.5
										}}>
											<Box sx={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
												mr: 1
											}}>
												<ErrorInfo />
											</Box>
											<Typography sx={{
												color: '#912018',
												mr: 2,
												fontSize: '15px'
											}}>{errors.email}</Typography>
										</Box>
									) : null}
								</Box>
								<Box sx={{ mt: 2 }}>
									<Typography sx={{ fontSize: 13, mb: 1 }}>Phone</Typography>
									<Box sx={{
										background: '#F6F8FC', borderRadius: '9px',
										fontSize: '14px', overflow: 'hidden',
										height: '50px',
										display: 'flex', pl: 1
									}}>
										<StyledPhoneInput
											international
											countryCallingCodeEditable={false}
											defaultCountry="US"
											placeholder="Enter phone number"
											onCountryChange={handleCountryChange}
											onChange={(phone) => setFieldValue('phone', phone)}
											value={values.phone}
											style={{
												width: '100%',
												border: 'none',
												background: 'transparent',
												fontSize: 'inherit'
											}}
										/>
										{touched.phone && errors.phone ? (
											<Box sx={{
												display: 'flex', alignItems: 'center',
												background: '#FEE4E2',
												mt: 1.5,
												pt: 0.5,
												pb: 0.5,
												pl: 0.5,
												borderRadius: '9px',
												pr: 0.5
											}}>
												<Box sx={{
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
													mr: 1
												}}>
													<ErrorInfo />
												</Box>
												<Typography sx={{
													color: '#912018',
													mr: 2,
													fontSize: '15px'
												}}>{errors.phone}</Typography>
											</Box>
										) : null}
									</Box>
								</Box>

								{PromoCodeComponent}

								<Box display={'flex'} justifyContent={'flex-start'}>
									<Box>

									</Box>
								</Box>
								<Box sx={{ mt: 2 }}>
									<FormControlLabel
										control={
											<Checkbox
												checked={checked}
												onChange={handleCheck}
												name="checked"
												color="primary"
												sx={{
													color: '#667085',
													'& .MuiCheckbox-root': {
														color: '#FEE4E2'
													}

													// color: '#FEE4E2', // цвет когда checkbox не выбран
													// '&.Mui-checked': {
													//     color: '#FEE4E2', // цвет когда checkbox выбран
													// },

												}}
											/>
										}
										label={
											<Typography sx={{ color: '#667085' }}>
												By signing up, I have read and agree to Docuease’s
												<Typography
													component="a"
													href="https://docuease.com/terms-and-conditions"
													sx={{
														color: '#444CC6',
														fontWeight: '600',
														cursor: 'pointer',
														marginLeft: '5px',
														zIndex: 9999
													}}
												>
													Terms & Conditions
												</Typography>
											</Typography>
										}
									/>
								</Box>

								<Box sx={{ display: 'flex', alignItems: 'center', mt: 3 }}>
									<Button
										type="submit"
										endIcon={!loading && <NextLogInIcon />}
										disabled={loading}
										// fullWidth
										variant="contained"
										sx={{
											background: 'linear-gradient(99.55deg, #276CFF 42.8%, #4A84FF 81.65%)',
											p: 3, borderRadius: 999, pt: 1, pb: 1, mr: 2
										}}
									>
										{loading ? <CircularProgress size={22} sx={{ color: 'white' }} /> :
											<Typography sx={{
												fontWeight: '500'
											}}>Proceed</Typography>}
									</Button>
									{googleAuth ? <CircularProgress size={20} sx={{
										color: 'linear-gradient(99.97deg, #64ABFF 0%, #444CC6 102.14%)'
									}} /> : (<GoogleLogin
										useOneTap={false}
										text={'Login with Google'}
										onSuccess={async credentialResponse => {
											setGoogleAuth(true);
											try {
												const data = await authSocialGoogle(credentialResponse.credential, invitationToken);
												if (data.task_id) {
													history(`/tasks/messenger/${data.task_id}`);
													return;
												}
												history('/tasks');
											} catch (error) {
												toast.error('Login Failed');
											} finally {
												setGoogleAuth(false);
											}
										}}
										shape={'circle'}
										type={'icon'}
										onError={() => {
											setGoogleAuth(false);
											return toast.error('Login Failed');
										}}
									/>)
									}
								</Box>
							</Form>
						)}

					</Formik>
				</>)
				}
			</Box>

		</AuthLayout>
	);
}


const mapActionToProps = dispatch =>
	bindActionCreators(
		{
			register: actions.register,
			confirm: actions.confirm,
			authSocialGoogle: actions.authSocialGoogle
		},
		dispatch
	);

export default connect(null, mapActionToProps)(Register);
