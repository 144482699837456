import { TYPE_SET_REFERENCE } from '../actions';


const initialState = {};

const referenceReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case TYPE_SET_REFERENCE:
			return payload;
		// case  TYPE_CREATE_TASK:
		// return {drive: [...[payload], ...state.drive]}

		default:
			return state;
	}
};

export default referenceReducer;
