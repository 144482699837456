import React, { useContext, useState } from 'react';
import Scrollbar from 'src/components/Scrollbar';
import { SidebarContext } from 'src/contexts/SidebarContext';

import {
	Box,
	Drawer,
	styled,
	Divider,
	useTheme,
	darken, Typography, IconButton, Button, OutlinedInput
} from '@mui/material';

import SidebarMenu from './SidebarMenu';
import Logo from 'src/components/LogoSign';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../store/actions';
import RefreshIcon from '@mui/icons-material/Refresh';
import { applyPromoCode, updatePurchaseModal } from '../../../store/actions';

import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';


const SidebarWrapper = styled(Box)(
	({ theme }) => `
      width: ${theme.sidebar.width};
      min-width: ${theme.sidebar.width};
      color: ${theme.colors.alpha.trueWhite[70]};
      position: relative;
      z-index: 7;
      height: 100%;
      padding-bottom: 68px;
      display: flex;
      flex-direction: column;
`
);

const ProgressBar = ({ progress }) => {
	const isHighProgress = progress > 90;
	return (
		<Box
			sx={{
				width: '100%',
				height: '10px',
				backgroundColor: '#070716',
				borderRadius: 999
			}}
		>
			<Box
				sx={{
					height: '100%',
					width: `${progress}%`,
					background: isHighProgress
						? 'linear-gradient(99.97deg, #FF6B6B 0%, #C64444 102.14%)'  // Red gradient
						: 'linear-gradient(99.97deg, #64ABFF 0%, #444CC6 102.14%)', // Blue gradient
					textAlign: 'center',
					lineHeight: '20px',
					color: 'white',
					borderRadius: 999
				}}
			>
			</Box>
		</Box>
	);
};

function Sidebar() {
	const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
	const [promoCode, setPromoCode] = useState('');
	const [promoCodeLoading, setPromoCodeLoading] = useState(false);

	const closeSidebar = () => toggleSidebar();
	const theme = useTheme();
	const dispatch = useDispatch();
	const [promoDialogOpen, setPromoDialogOpen] = useState(false);

	const user = useSelector(state => state.user);
	const percentage = Math.min(user.accountInfo?.subscription?.remaining_credit_percentage || 0, 100);

	const hasPromoCode = !!user?.accountInfo?.subscription?.discount_info;

	const planName = user.accountInfo?.subscription?.plan_name || '';

	const handleOpenPromoDialog = () => setPromoDialogOpen(true);
	const handleClosePromoDialog = () => setPromoDialogOpen(false);

	const handleApplyPromoCode = async () => {
		setPromoCodeLoading(true);
		await dispatch(applyPromoCode(promoCode));
		setPromoCodeLoading(false);
		handleClosePromoDialog();
	};


	return (
		<>
			<SidebarWrapper
				sx={{
					display: {
						xs: 'none',
						lg: 'inline-block'
					},
					position: 'fixed',
					left: 0,
					top: 0,
					background: '#070716'
					// boxShadow:
					//   theme.palette.mode === 'dark' ? theme.sidebar.boxShadow : 'none'
				}}
			>

				<Scrollbar style={{ height: planName === 'Free Plan' ? '80%' : '93%' }}>
					<Box mt={3} mb={3}>
						<Box
							mx={2}
							sx={{
								width: 42
							}}
						>
							<Logo />
						</Box>
					</Box>
					<SidebarMenu />
				</Scrollbar>

				{planName === 'Free Plan' && (<Box sx={{ pr: 2, pl: 2 }}>
					<Box sx={{
						background: '#1D1D37',
						p: 2,
						borderRadius: '16px'
					}}>

						<Button sx={{
							p: 1, background: '#444CC6', width: '100%', mb: 1.5, borderRadius: 999,
							'&:hover': {
								background: '#444CC6' // сохраняем тот же фон при наведении
								// Вы также можете добавить другие стили, если хотите изменить эффект при наведении
							}
						}} onClick={() => dispatch(updatePurchaseModal(true))}>
							<Typography sx={{
								color: 'white',
								fontWeight: '500'
							}}>Upgrade Your Plan 🚀</Typography>
						</Button>

						{
							!hasPromoCode && (
								<Button sx={{
									p: 1, background: '#292952', width: '100%', mb: 1, borderRadius: 999, '&:hover': {
										background: '#3a3a5c'
									}
								}} onClick={handleOpenPromoDialog}>
									<Typography sx={{
										color: 'white', fontWeight: '500'
									}}>Apply Promo Code</Typography>
								</Button>
							)
						}


						<Box>
							<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
								<Typography sx={{
									fontSize: '14px',
									fontWeight: '600',
									color: 'white'
								}}>{planName}</Typography>
								<IconButton sx={{ p: 0, pr: 0.5, pl: 0.5 }} aria-label="reload"
								            onClick={async () => await dispatch(actions.getUser())}>
									<RefreshIcon sx={{ color: 'white', fontSize: 18 }} />
								</IconButton>

							</Box>
							{planName === 'Free Plan' && (
								<>
									<Box sx={{ mt: 1.5, mb: 1 }}>

										<ProgressBar progress={percentage} />
									</Box>
									<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
										<Typography sx={{
											fontSize: '12px',
											fontWeight: '500',
											display: 'flex',
											flexDirection: 'row',
											color: '#676786'
										}}>You’ve used <Typography sx={{
											fontSize: '12px',
											fontWeight: '600',
											color: 'white',
											mr: 0.4, ml: 0.4
										}}
										>{percentage}%</Typography> of your trial limit</Typography>
									</Box></>)}
						</Box>
					</Box>
				</Box>)}
			</SidebarWrapper>

			<Dialog open={promoDialogOpen} onClose={handleClosePromoDialog}
			        sx={{ '& .MuiDialog-paper': { backgroundColor: 'white', color: 'black' } }}>
				<DialogTitle sx={{ backgroundColor: '#f0f0f0' }}>Apply Promo Code</DialogTitle>
				<DialogContent sx={{ backgroundColor: '#f0f0f0' }}>
					<OutlinedInput
						onChange={event => setPromoCode(event.target.value)}
						margin="normal"
						required
						autoFocus
						id="promoCode"
						placeholder="Promo Code"
						type="text"
						fullWidth
						variant="outlined"
						sx={{
							border: '1px solid #EAECF0',
							borderTopRightRadius: 0,
							'& .MuiOutlinedInput-notchedOutline': {
								borderWidth: 0 // Setting border-width to 0 will remove the border
							},
							'& fieldset': { border: 'none' }
						}}
					/>
				</DialogContent>
				<DialogActions sx={{ borderTop: '1px solid #e0e0e0', backgroundColor: '#f0f0f0' }}>
					<Button onClick={handleClosePromoDialog} sx={{ color: '#333' }}>Cancel</Button>
					<LoadingButton
						loading={promoCodeLoading}
						onClick={handleApplyPromoCode}
						sx={{ color: '#333' }}
					>
						Apply
					</LoadingButton>
				</DialogActions>
			</Dialog>
			<Drawer
				sx={{
					boxShadow: `${theme.sidebar.boxShadow}`
				}}
				anchor={theme.direction === 'rtl' ? 'right' : 'left'}
				open={sidebarToggle}
				onClose={closeSidebar}
				variant="temporary"
				elevation={9}
			>
				<SidebarWrapper
					sx={{
						background:

							theme.palette.mode === 'dark'
								? theme.colors.alpha.white[100]
								: darken(theme.colors.alpha.black[100], 0.5)

					}}
				>
					<Scrollbar>
						<Box mt={3}>
							<Box
								mx={2}
								sx={{
									width: 52
								}}
							>
								<Logo />
							</Box>
						</Box>
						<Divider
							sx={{
								mt: theme.spacing(3),
								mx: theme.spacing(2),
								background: theme.colors.alpha.trueWhite[10]
							}}
						/>
						<SidebarMenu />
					</Scrollbar>
				</SidebarWrapper>
			</Drawer>
		</>
	);
}

export default Sidebar;
