export async function stringToSHA1(str) {
	// Convert the string to an ArrayBuffer
	const buffer = new TextEncoder().encode(str);

	// Use the Web Crypto API to hash the buffer
	const hashBuffer = await crypto.subtle.digest('SHA-1', buffer);

	// Convert the hash to a hexadecimal string
	const hashArray = Array.from(new Uint8Array(hashBuffer));
	const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');

	return hashHex;
}
