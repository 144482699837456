import { useRoutes } from 'react-router-dom';
import router from 'src/router';
import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import React, { useEffect } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import 'react-toastify/dist/ReactToastify.css';
import 'core-js/features/array/at';
import { ToastContainer } from 'react-toastify';
import Gleap from 'gleap';
import useImpactIdentify from './hooks/impact-identify';


const skdGleapKey = process.env.REACT_APP_SDK_GLEAP_KEY || '';
const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || '';

function App() {
	const content = useRoutes(router);

	useEffect(() => {
		Gleap.initialize(skdGleapKey);
	}, []);

	useImpactIdentify();
	console.log(process.env.REACT_APP_STIPE_PUBLISH_KEY);

	return (
		<GoogleOAuthProvider clientId={googleClientId}>
			<ThemeProvider>
				<CssBaseline />
				{content}
				<ToastContainer />
			</ThemeProvider>
		</GoogleOAuthProvider>
	);
}

export default App;
