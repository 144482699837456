import axios from '../../utils/axios';
import { toast } from 'react-toastify';


export const INCREMENT_COUNT_REGULAR_CHAT = 'INCREMENT_COUNT_REGULAR_CHAT';

export const TYPE_SET_TOOLTIP = 'TYPE_SET_TOOLTIP';

export const TYPE_REMOVE_TOOLTIP = 'TYPE_REMOVE_TOOLTIP';

export const TYPE_SET_FILE_EXIST = 'TYPE_SET_FILE_EXIST';

export const TYPE_REMOVE_ALL_TOOLTIP = 'TYPE_REMOVE_ALL_TOOLTIP';


export const incrementCountRegularChat = (type = 'regular_ai') => {
	return dispatch => {
		dispatch({
			type: INCREMENT_COUNT_REGULAR_CHAT,
			payload: {
				type: type
			}
		});

	};
};


export const disableModalUploadDocument = (isActive) => {
	return dispatch => {
		dispatch({
			type: TYPE_SET_FILE_EXIST,
			payload: isActive
		});

	};
};


export const createTooltipKeys = (tooltipKeys) => {
	return dispatch => {
		dispatch({
			type: TYPE_SET_TOOLTIP,
			payload: {
				tooltipKeys: tooltipKeys
			}
		});

	};
};


export const deleteTooltipKeys = (tooltipKey) => {
	return async dispatch => {
		dispatch({
			type: TYPE_REMOVE_TOOLTIP,
			payload: {
				tooltipKey: tooltipKey
			}
		});

	};
};

export const deleteTooltipAllKeys = () => {
	return async dispatch => {
		dispatch({
			type: TYPE_REMOVE_ALL_TOOLTIP
		});

	};
};


export const resetEventTrigger = () => {
	return async dispatch => {
		try {
			await axios.delete(`/api/v1/user_trigger_service/reset-event`);
			toast.success('Platfrom Tutorial Successfuly Restarted. Upload Your First Focument!', {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true
			});

		} catch (e) {
			toast.error(`An error has occurred`, {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true

			});
		}
	};
};
