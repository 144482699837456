import ReactDOM from 'react-dom';
import ReactGA from 'react-ga4';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import 'typeface-inter';
import 'nprogress/nprogress.css';
import App from './App';
import { SidebarProvider } from './contexts/SidebarContext';
import * as serviceWorker from './serviceWorker';
import { store } from './store';
import './global.css';


ReactGA.initialize('G-9VH8SV7JGC');


ReactDOM.render(
	<Provider store={store}>
		<HelmetProvider>
			<SidebarProvider>
				<BrowserRouter>
					<App />
				</BrowserRouter>
			</SidebarProvider>
		</HelmetProvider>
	</Provider>,
	document.getElementById('root')
);

serviceWorker.unregister();
