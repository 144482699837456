import { TYPE_CLEAR_SELECTED_MESSAGE, TYPE_SET_SELECTED_MESSAGE } from '../actions/selectedMessage';


const initialState = { isLoading: true, message: [] };

const selectedMessageReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {

		case TYPE_SET_SELECTED_MESSAGE:
			// Если isRefresh равно true, то заменяем весь массив, иначе добавляем к текущему
			return {
				...state,
				message: payload.isRefresh ? payload.messages : [...state.message, ...payload.messages],
				isLoading: false
			};

		case TYPE_CLEAR_SELECTED_MESSAGE:
			return {
				...state,
				message: []
			};

		default:
			return state;
	}
};
export default selectedMessageReducer;
