export function formatDate(dateString) {
	if (!dateString) {
		return '';
	}
	const date = new Date(dateString);
	const now = new Date();
	const diffInSeconds = Math.floor((now - date) / 1000);

	if (diffInSeconds < 60) {
		return `${diffInSeconds} seconds ago`;
	} else if (diffInSeconds < 3600) {
		const minutes = Math.floor(diffInSeconds / 60);
		return `${minutes} minutes ago`;
	} else if (diffInSeconds < 86400) {
		const hours = Math.floor(diffInSeconds / 3600);
		return `${hours} hours ago`;
	} else {
		const options = {
			day: 'numeric',
			month: 'short',
			year: 'numeric'
		};
		return new Intl.DateTimeFormat('en-US', options).format(date);
	}
}

export function timeAgo(created_at) {
	const currentDate = new Date();
	const createdAtDate = new Date(created_at);
	const secondsDiff = Math.floor((currentDate - createdAtDate) / 1000);

	// Seconds
	if (secondsDiff < 60) {
		return secondsDiff === 1 ? '1 second ago' : `${secondsDiff} seconds ago`;
	}
	// Minutes
	const minutesDiff = Math.floor(secondsDiff / 60);
	if (minutesDiff < 60) {
		return minutesDiff === 1 ? '1 minute ago' : `${minutesDiff} minutes ago`;
	}
	// Hours
	const hoursDiff = Math.floor(minutesDiff / 60);
	if (hoursDiff < 24) {
		return hoursDiff === 1 ? '1 hour ago' : `${hoursDiff} hours ago`;
	}
	// Days
	const daysDiff = Math.floor(hoursDiff / 24);
	if (daysDiff < 7) {
		return daysDiff === 1 ? '1 day ago' : `${daysDiff} days ago`;
	}
	// Weeks
	const weeksDiff = Math.floor(daysDiff / 7);
	if (weeksDiff < 4) {
		return weeksDiff === 1 ? '1 week ago' : `${weeksDiff} weeks ago`;
	}
	// Months
	// Примечание: тут предполагается, что месяц это приблизительно 4 недели.
	const monthsDiff = Math.floor(weeksDiff / 4);
	if (monthsDiff < 12) {
		return monthsDiff === 1 ? '1 month ago' : `${monthsDiff} months ago`;
	}
	// Years
	const yearsDiff = Math.floor(monthsDiff / 12);
	return yearsDiff === 1 ? '1 year ago' : `${yearsDiff} years ago`;
}


// const originalDateString = "2023-07-19T21:54:50.709472Z";
// const formattedDate = formatDate(originalDateString);


export function hasPassedOneDay(dateString) {
	// Преобразуем строку с датой в объект Date
	const givenDate = new Date(dateString);

	// Получаем текущую дату и время
	const currentDate = new Date();

	// Вычисляем разницу в миллисекундах между текущей датой и заданной датой
	const timeDiff = currentDate.getTime() - givenDate.getTime();

	// Количество миллисекунд в двух минутах
	const twoMinutesInMilliseconds = 2 * 60 * 1000;

	// Проверяем условия
	if (timeDiff > twoMinutesInMilliseconds) {
		return false;  // Прошло более двух минут
	} else if (timeDiff < 0) {
		return true;   // Время "перекатило" назад
	} else {
		return true;   // Прошло менее двух минут
	}
}


export function toLocalTime(utcDateStr) {
	let utcDate = new Date(utcDateStr);
	let localDate = new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000);
	return localDate;
}

export function getTimeLeft(endTimeStr, now) {
	let durationParts = endTimeStr.split(':');
	let hours = Number(durationParts[0]);
	let minutes = Number(durationParts[1]);
	let seconds = Number(durationParts[2]);

	let endTime = new Date(now);
	endTime.setHours(hours);
	endTime.setMinutes(minutes);
	endTime.setSeconds(seconds);

	let diffMs = endTime - now; // milliseconds between now and end time
	if (diffMs < 0) {
		// If the end time is in the past, add 24 hours (in milliseconds)
		diffMs += 24 * 60 * 60 * 1000;
	}

	let diffMinutes = Math.floor(diffMs / 60000); // convert milliseconds to minutes
	let diffHours = Math.floor(diffMinutes / 60); // convert minutes to hours
	diffMinutes = diffMinutes % 60; // get remaining minutes

	let timeLeft = (diffHours < 10 ? '0' : '') + diffHours + ':' +
		(diffMinutes < 10 ? '0' : '') + diffMinutes;
	return timeLeft;
}


export function formatDateSub(dateStr) {
	if (!dateStr) return ' ';

	// Parse the date
	const date = new Date(dateStr);

	// Define an array of month names
	const monthNames = [
		'January', 'February', 'March', 'April', 'May', 'June',
		'July', 'August', 'September', 'October', 'November', 'December'
	];

	// Format the date
	const day = date.getDate();
	const monthIndex = date.getMonth();
	const year = date.getFullYear();

	return `${monthNames[monthIndex]} ${day}, ${year}`;
}
