import { useEffect, useState } from 'react';


/**
 * Custom hook for loading an external script.
 * @param {string} src - The source URL of the script to be loaded.
 * @returns {boolean} - The loading state of the script (true when loaded).
 */
function useScript(src) {
	const [loaded, setLoaded] = useState(false);
	const [error, setError] = useState(null);

	useEffect(() => {
		// Check if the script is already present in the document
		let script = document.querySelector(`script[src="${src}"]`);

		if (!script) {
			script = document.createElement('script');
			script.src = src;
			script.async = true;
			script.setAttribute('data-status', 'loading');
			// Add script to document body
			document.body.appendChild(script);

			const setAttributes = (status) => {
				script.setAttribute('data-status', status);
				setLoaded(status === 'loaded');
				setError(status === 'error' ? 'Error loading script' : null);
			};

			script.onload = () => {
				setAttributes('loaded');
			};

			script.onerror = () => {
				setAttributes('error');
			};
		} else {
			// Handle already loaded or loading scripts
			setLoaded(script.getAttribute('data-status') === 'loaded');
			setError(script.getAttribute('data-status') === 'error' ? 'Error loading script' : null);
		}

		// Remove script from document on cleanup
		return () => {
			if (script) {
				script.onload = null;
				script.onerror = null;
				document.body.removeChild(script);
			}
		};
	}, [src]); // Only re-run effect if script src changes

	return [loaded, error];
}

export default useScript;
