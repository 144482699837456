import React, { Suspense, lazy, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import Register from './content/overview/Register';
import FolderDetail from './content/applications/Drive/FolderDetail';
import BlockMobileLayout from './layouts/BlockMobileLayout';
import { getUser, updatePurchaseModal } from './store/actions';
import { useDispatch, useSelector } from 'react-redux';
import PurchaseModal from './components/PurchaseModal';


const ProtectedRoute = ({ element }) => {
	return !localStorage.getItem('token') ? element : <Navigate to="/tasks" replace />;
};

const AuthRoute = ({ element }) => {
	const dispatch = useDispatch();
	const history = useNavigate();
	const modalOpen = useSelector(state => state.payment.purchaseModal);
	const user = useSelector(state => state.user.accountInfo);
	useEffect(() => {
		const initData = async () => {
			try {
				const user = await dispatch(getUser());

				if (user?.has_access) {
					history('/payment/success');
				}

				if (user?.subscription?.remaining_credit_percentage >= 90) {
					dispatch(updatePurchaseModal(true));
				}

			} catch (error) {
				console.error('Ошибка при получении данных пользователя:', error);
			}
		};
		initData();
	}, [dispatch, history]);


	console.log(user?.subscription);
	return localStorage.getItem('token') ? <>
		{element}
		<PurchaseModal
			open={modalOpen}
			onClose={() => dispatch(updatePurchaseModal(false))} />
	</> : <Navigate to="/" replace />;
};


const Loader = (Component) => (props) =>
	(
		<Suspense fallback={<SuspenseLoader />}>
			<Component {...props} />
		</Suspense>
	);

// Pages

const Overview = Loader(lazy(() => import('src/content/overview')));

// Dashboards

// Applications

const Messenger = Loader(
	lazy(() => import('src/content/applications/Messenger'))
);
const Transactions = Loader(
	lazy(() => import('src/content/applications/Transactions'))
);


const Status404 = Loader(
	lazy(() => import('src/content/pages/Status/Status404'))
);

const StatusComingSoon = Loader(
	lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
	lazy(() => import('src/content/pages/Status/Maintenance'))
);

const SelectPlan = Loader(
	lazy(() => import('src/content/overview/SelectPlan'))
);

const Drive = Loader(
	lazy(() => import('src/content/applications/Drive'))
);

const Settings = Loader(
	lazy(() => import('src/content/settings'))
);


const Editor = Loader(
	lazy(() => import('src/content/editor'))
);

const PaymentSuccess = Loader(
	lazy(() => import('src/content/pages/Status/Success'))
);

const Draft = Loader(
	lazy(() => import('src/content/draft'))
);

const ForgotPassword = Loader(
	lazy(() => import('src/content/overview/ForgotPassword'))
);


const routes = [
	{
		path: '',
		element: <BaseLayout />,
		children: [
			{
				path: '/',
				element: <ProtectedRoute element={<Overview />} />
			},
			{
				path: 'register',
				element: <Register />
			},
			{
				path: 'forgot-password',
				element: <ProtectedRoute element={<ForgotPassword />} />
			},
			// {
			//   path: 'overview',
			//   element:  <ProtectedRoute element={<OverviewBeta/>}/>
			//
			// },
			{
				path: 'select-plan',
				element: <SelectPlan />
			},
			{
				path: 'status',
				children: [
					{
						path: '',
						element: <Navigate to="404" replace />
					},
					{
						path: '404',
						element: <Status404 />
					},
					{
						path: 'maintenance',
						element: <StatusMaintenance />
					},
					{
						path: 'coming-soon',
						element: <StatusComingSoon />
					}
				]
			},
			{
				path: '*',
				element: <Status404 />
			}
		]
	},
	{
		path: '',
		element: <SidebarLayout />,
		children: [
			{
				path: 'messenger',
				element: <AuthRoute element={<Messenger />} />
			}
		]
	},
	{
		path: 'tasks',
		element: <SidebarLayout />,
		children: [
			// {
			//   path: '',
			//   element: <Navigate to="celery_tasks" replace />
			// },
			{
				path: '',
				element: <AuthRoute element={<Transactions />} />
			},
			{
				path: 'messenger/:id',
				element: <AuthRoute element={<Messenger />} />
			}
		]
	},
	{
		path: 'drive',
		element: <SidebarLayout />,
		children: [
			// {
			//   path: '',
			//   element: <Navigate to="celery_tasks" replace />
			// },
			{
				path: '',
				element: <AuthRoute element={<Drive />} />
			},
			{
				path: 'folder/:id',
				element: <AuthRoute element={<FolderDetail />} />
			}

		]
	},
	{
		path: 'editor',
		element: <BlockMobileLayout />,
		children: [
			{
				path: '',
				element: <AuthRoute element={<Editor />} />
			},
			{
				path: 'list/:id',
				element: <AuthRoute element={<Editor />} />
			}

		]
	},

	{
		path: 'payment',
		element: <BaseLayout />,
		children: [
			{
				path: 'success',
				element: <AuthRoute element={<PaymentSuccess />} />
			}

		]
	},

	{
		path: 'settings',
		element: <SidebarLayout />,
		children: [
			{
				path: '',
				element: <Navigate to="general" replace />
			},
			{
				path: ':path',
				element: <AuthRoute element={<Settings />} />
			}
			// {
			//   path: 'folder/:id',
			//   element: <FolderDetail/>
			// }

		]
	},
	{
		path: 'draft',
		element: <SidebarLayout />,
		children: [
			{
				path: '',
				element: <AuthRoute element={<Draft />} />
			}
			// {
			//   path: 'folder/:id',
			//   element: <FolderDetail/>
			// }

		]
	}

];

export default routes;
