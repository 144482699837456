import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { stringToSHA1 } from '../utils/string-to-sha1';
import useScript from './script';


const context = 'Impact Identify';

const useImpactIdentify = () => {
	const location = useLocation();
	const user = useSelector(state => state.user);
	const { email, id } = user.accountInfo || {};
	const [loaded, error] = useScript('https://utt.impactcdn.com/A5372526-72cd-4c9f-85ba-1e337477e1691.js');

	useEffect(() => {
		if (error) {
			console.log(`${context} - impact not loaded`, error);
		}
	}, [error]);


	useEffect(() => {
		const startIdentifyFunction = async () => {
			const hashedEmail = email ? await stringToSHA1(email) : '';

			try {

				if (error) {
					console.log(`${context} - impact not loaded`, error);
				}


				if (window.ire) {
					window.ire('identify', {
						customerId: id || '',
						customerEmail: hashedEmail || '',
						customProfileId: ''
					});

				} else {
					console.log(`${context} - ire not found`);
				}
			} catch (e) {
				console.log(`${context} - error`, e);
			}
		};

		startIdentifyFunction().catch((e) => {
			console.log(`${context} - error (invoked)`, e);
		});
	}, [email, id, location, loaded, user, error]);
};

export default useImpactIdentify;
