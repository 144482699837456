import {
	TYPE_CLEAR_FOLDER_FILES,
	TYPE_CREATE_FILE,
	TYPE_CREATE_FILE_BY_FOLDER,
	TYPE_CREATE_FOLDER,
	TYPE_DELETE_DRIVE_FILE,
	TYPE_DELETE_FOLDER,
	TYPE_DELETE_FOLDER_FILE,
	TYPE_SET_DRIVE,
	TYPE_SET_FILE_BY_FOLDER,
	TYPE_UPDATE_FILE_NAME,
	TYPE_UPDATE_FOLDER_FILE_NAME,
	TYPE_UPDATE_FOLDER_NAME
} from '../actions';


const initialState = {
	folder: [],
	file: [],
	fileByFolder: [],
	currentFolderName: ''
};


const driveReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case TYPE_SET_DRIVE:
			if (payload.isRefresh) {
				return { ...state, folder: payload.folder, file: payload.file };
			}
			return { ...state, folder: [...state.folder, ...payload.folder], file: [...state.file, ...payload.file] };

		case TYPE_CREATE_FOLDER:
			return { ...state, folder: [...state.folder, payload.folder] };

		case TYPE_CREATE_FILE:
			return { ...state, file: [payload.file, ...state.file] };

		case TYPE_SET_FILE_BY_FOLDER:
			return { ...state, fileByFolder: payload.file, currentFolderName: payload.folderName };

		case TYPE_CREATE_FILE_BY_FOLDER:
			return { ...state, fileByFolder: [payload.file, ...state.fileByFolder] };

		case TYPE_UPDATE_FOLDER_NAME:
			return {
				...state,
				folder: state.folder.map(folder =>
					folder.id === payload.id ? { ...folder, name: payload.name } : folder
				)
			};

		case TYPE_DELETE_FOLDER:
			return {
				...state,
				folder: state.folder.filter(folder => folder.id !== payload.id)
			};

		case TYPE_UPDATE_FOLDER_FILE_NAME:
			return {
				...state,
				fileByFolder: state.fileByFolder.map(file =>
					file.id === payload.id ? { ...file, name: payload.name } : file
				)
			};

		case TYPE_UPDATE_FILE_NAME:
			return {
				...state,
				file: state.file.map(file =>
					file.id === payload.id ? { ...file, name: payload.name } : file
				)
			};

		case TYPE_DELETE_FOLDER_FILE:
			return {
				...state,
				fileByFolder: state.fileByFolder.filter(file => file.id !== payload.id)
			};

		case TYPE_DELETE_DRIVE_FILE:
			return {
				...state,
				file: state.file.filter(file => file.id !== payload.id)
			};

		case TYPE_CLEAR_FOLDER_FILES:
			return {
				...state,
				fileByFolder: [],
				currentFolderName: ''
			};


		default:
			return state;
	}
};
export default driveReducer;
