import {
	ADD_VOICE_TONE,
	CLEAR_CURRENT_VOICE_TONE,
	DELETE_VOICE_TONE_BY_ID,
	RESET_TASK_EXISTS_FOR_VOICE_TONE,
	SET_CURRENT_VOICE_TONE,
	SET_VOICE_TONE,
	UPDATE_VOICE_TONE_BY_ID,
	UPDATE_VOICE_TONE_TASK_EXISTS
} from '../actions';


const initialState = {
	currentVoiceTone: {},
	voiceTone: []
};


const voiceToneReducers = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case SET_CURRENT_VOICE_TONE:
			return {
				...state,
				currentVoiceTone: payload
			};

		case SET_VOICE_TONE:
			return {
				...state,
				voiceTone: [...payload]
			};

		case 'REMOVE_VOICE_TONE':
			return {
				...state,
				voiceTone: state.voiceTone.filter(voice => voice.id !== payload.id)
			};

		case ADD_VOICE_TONE:
			return {
				...state,
				voiceTone: [payload, ...state.voiceTone]
			};

		case UPDATE_VOICE_TONE_TASK_EXISTS:
			let updatedCurrentVoiceTone = state.currentVoiceTone;
			const updatedVoiceTones = state.voiceTone.map(voice => {
				if (voice.id === payload.id) {
					updatedCurrentVoiceTone = { ...voice, task_exists: true };
					return updatedCurrentVoiceTone;
				} else {
					return {
						...voice,
						task_exists: voice.task_exists ? false : voice.task_exists
					};
				}
			});
			return {
				...state,
				currentVoiceTone: updatedCurrentVoiceTone,
				voiceTone: updatedVoiceTones
			};

		case RESET_TASK_EXISTS_FOR_VOICE_TONE:
			return {
				...state,
				currentVoiceTone: state.currentVoiceTone?.id === payload ? {} : state.currentVoiceTone,
				voiceTone: state.voiceTone.map(voice => {
					if (voice.id === payload) {
						return {
							...voice,
							task_exists: false
						};
					}
					return voice;
				})
			};

		case DELETE_VOICE_TONE_BY_ID:
			return {
				...state,
				currentVoiceTone: state.currentVoiceTone?.id === payload ? {} : state.currentVoiceTone,
				voiceTone: state.voiceTone.filter(voice => voice.id !== payload)
			};


		case CLEAR_CURRENT_VOICE_TONE:
			return {
				...state,
				currentVoiceTone: {}
			};

		case UPDATE_VOICE_TONE_BY_ID:

			return {
				...state,
				currentVoiceTone: state.currentVoiceTone?.id === payload.id ? {
					id: payload.id,
					name: payload.name,
					template: payload.template,
					task_exists: state.currentVoiceTone.task_exists
				} : state.currentVoiceTone,

				voiceTone: state.voiceTone.map(voice => {
					if (voice.id === payload.id) {
						return {
							...voice,
							name: payload.name,
							template: payload.template
						};
					}
					return voice;
				})
			};


		default:
			return state;
	}
};

export default voiceToneReducers;
