export function signin({ user, token, permissions: userPermissions }) {
	localStorage.setItem('token', token);
	localStorage.setItem('permissions', JSON.stringify(userPermissions));
	// history('/home')
}

export function signOutFunc() {
	localStorage.removeItem('token');
	localStorage.removeItem('permissions');
	// history('/')
}

export function isAuthenticated() {
	return localStorage.getItem('token')
		&& localStorage.getItem('permissions');
}
