import React, { useState } from 'react';
import { Box, Chip, DialogActions, DialogContent, OutlinedInput, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import axios from '../../../utils/axios';
import { toast } from 'react-toastify';
import LoadingButton from '@mui/lab/LoadingButton';


const checkPromoCode = async (code) => {
	try {
		const result = await axios.post('/api/v1/payment/check-promo', { promo_code: code });

		if (result?.message) {
			toast.success(result.message);

			return true;
		}

	} catch (e) {
		if (e.error) {
			toast.error(e.error, {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true
			});

			return;
		}

		toast.error(`Error receiving. Try again later`, {
			position: 'top-center',
			autoClose: 3000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true
		});
	}
};

export function usePromoCode() {
	const [showModal, setShowModal] = useState(false);
	const [promoCode, setPromoCode] = useState('');
	const [loading, setLoading] = useState(false);
	const [isCorrect, setIsCorrect] = useState(false);


	const handleClickOpen = () => {
		setShowModal(true);
	};

	const handleClose = () => {
		setShowModal(false);
	};

	const handleChange = (e) => {
		setPromoCode(e.target.value);
	};

	const handleSubmit = async () => {
		setLoading(true);
		const isSuccess = await checkPromoCode(promoCode);

		if (isSuccess) {
			setIsCorrect(true);
			handleClose();
		}
		setLoading(false);
	};

	const PromoCodeLabel = (
		<Box sx={{ mt: 2 }}>
			<Chip label={`Promo Code: ${promoCode}`} color="success" variant="outlined" />

		</Box>
	);

	const PromoCodeModal = (
		<React.Fragment>
			<Box sx={{ mt: 2 }}>
				<Button
					type={'button'}
					variant="contained"
					color="success"
					onClick={handleClickOpen}
				>
					<Typography>Apply Promo Code</Typography>
				</Button>
			</Box>

			<Dialog
				open={showModal}
				onClose={handleClose}
				PaperProps={{
					style: {
						backgroundColor: 'white' // Set your desired color
					}
				}}
			>
				<DialogTitle style={{ fontWeight: 'bold' }}>Promo Code</DialogTitle>
				<DialogContent>
					<OutlinedInput
						onChange={handleChange}
						autoFocus
						required
						margin="dense"
						type="text"
						fullWidth
						variant="standard"
					/>
				</DialogContent>

				<DialogActions>
					<Button onClick={handleClose}>Cancel</Button>
					<LoadingButton
						type="button"
						onClick={handleSubmit}
						loading={loading}
					>
						Apply
					</LoadingButton>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);

	return {
		PromoCodeComponent: isCorrect ? PromoCodeLabel : PromoCodeModal,
		promoCode
	};
}
