import axios from '../../utils/axios';
import { toast } from 'react-toastify';


export const TYPE_SET_FAVORITE_LIST = 'TYPE_SET_FAVORITE_LIST';

export const TYPE_ADD_FAVORITE = 'TYPE_ADD_FAVORITE';

export const TYPE_DELETE_FAVORITE = 'TYPE_DELETE_FAVORITE';


export const addFavorite = buttonId => {
	return async dispatch => {
		try {
			await axios.post(`/api/v1/chatgpt/favorite/${buttonId}`);

			dispatch({
				type: TYPE_ADD_FAVORITE,
				payload: { id: buttonId }
			});
		} catch (e) {
			toast.error(`An error has occurred`, {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true

			});
		}
	};
};


export const deleteFavorite = buttonId => {
	return async dispatch => {
		try {
			await axios.delete(`/api/v1/chatgpt/favorite/${buttonId}`);

			dispatch({
				type: TYPE_DELETE_FAVORITE,
				payload: { id: buttonId }
			});
		} catch (e) {
			toast.error(`An error has occurred`, {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true

			});
		}
	};
};
