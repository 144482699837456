import React from 'react';
import { Box, CssBaseline, Grid, Paper } from '@mui/material';
import ImageBackground from '../../static/image/authbackground.png';
import AuthDashboard from '../../static/image/auth_dashboard.png';
import logo from '../../static/image/auth_logo.png';
import { Helmet } from 'react-helmet-async';


export default function AuthLayout({ children }) {
	return (
		<>
			<Helmet>
				<title>DocuEase App</title>
			</Helmet>
			<Grid container component="main" sx={{ height: '100vh' }}>
				<CssBaseline />
				<Grid
					item
					xs={false}
					sm={false}
					md={6}
					lg={6}
					sx={{
						backgroundImage: `linear-gradient(153.69deg, #05011D 0%, #0D7BF8 100%)`,
						backgroundRepeat: 'no-repeat',
						display: { xs: 'none', md: 'block' }
					}}
				>
					<Box
						sx={{
							width: '100%',
							height: '100%',
							display: 'flex',
							alignItems: 'center',
							backgroundImage: `url(${ImageBackground})`,
							// backgroundRepeat: 'no-repeat',
							backgroundSize: 'cover',
							zIndex: 1 // Set a negative zIndex to place the image behind the content
						}}
					>
						<img src={AuthDashboard} style={{
							objectFit: 'contain',
							width: '40vw'
						}} alt={'dashboard'} />
						<img src={logo} style={{
							position: 'absolute',
							width: 150,
							left: '33vw',
							top: '60%'
						}} alt={'logo'} />
					</Box>

				</Grid>

				<Grid item xs={12} sm={12} md={6} lg={6} component={Paper} elevation={6} square>
					{children}
				</Grid>
			</Grid>
		</>

	);
}
