import {
	Box,
	styled
} from '@mui/material';
import { Link } from 'react-router-dom';
import LogoImage from '../../static/image/logo.png';


const LogoWrapper = styled(Link)(
	({ theme }) => `
        color: ${theme.palette.text.primary};
        display: flex;
        text-decoration: none;
        width: 53px;
        margin: 0 auto;
        font-weight: ${theme.typography.fontWeightBold};
`
);


function Logo() {

	return (
		<Box
			title=""
			arrow
		>
			<LogoWrapper to="/tasks">

				<Box
					component="img"
					sx={{
						height: 20,
						width: 150,
						objectFit: 'contain'
						// maxHeight: { xs: 233, md: 167 },
						// maxWidth: { xs: 350, md: 250 },
					}}
					alt="The house from the offer."
					src={LogoImage}
				/>


			</LogoWrapper>
		</Box>
	);
}

export default Logo;
