import React from 'react';
import { TextField } from '@mui/material';
import { useField } from 'formik';


const CustomTextField = ({ label, ...props }) => {
	const [field, meta] = useField(props.name);
	const showError = meta.touched && Boolean(meta.error);

	return (
		<TextField
			label={label}
			{...field}
			{...props}
			error={showError}
			helperText={meta.touched && meta.error}
		/>
	);
};

export default CustomTextField;
