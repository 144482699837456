import {
	TYPE_CHANGE_PHOTO_SUCCESS,
	TYPE_CLEAR_DISCOUNT_INFO,
	TYPE_FINISHED_UPDATE_AVATAR,
	TYPE_FINISHED_UPDATE_USER,
	TYPE_SET_PROFILE_SUCCESS,
	TYPE_START_UPDATE_AVATAR,
	TYPE_START_UPDATE_USER,
	TYPE_UPDATE_ACCESS_STATUS,
	TYPE_UPDATE_DISCOUNT_INFO,
	TYPE_UPDATE_SUBSCRIPTION_STATUS,
	TYPE_UPDATE_USER_ERROR,
	TYPE_UPDATE_USER_SUCCESS
} from '../actions';


const initialState = {
	accountInfo: {}, error: null, loading: {
		updatePhoto: false,
		updateUser: false
	}
};

const userReducers = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case TYPE_SET_PROFILE_SUCCESS:
			return { ...state, accountInfo: { ...payload } };

		case TYPE_CHANGE_PHOTO_SUCCESS:
			return { ...state, accountInfo: { ...state.accountInfo, image: payload } };

		case TYPE_UPDATE_USER_SUCCESS:
			return { ...state, accountInfo: { ...state.accountInfo, ...payload } };

		case TYPE_UPDATE_USER_ERROR:
			return { ...state, error: payload };

		case TYPE_UPDATE_DISCOUNT_INFO:
			return {
				...state,
				accountInfo: {
					...state.accountInfo,
					subscription: {
						...state.accountInfo.subscription,
						discount_info: payload
					}
				}
			};

		case TYPE_START_UPDATE_USER:
			return { ...state, error: null, loading: { ...state.loading, updateUser: true } };
		case TYPE_FINISHED_UPDATE_USER:
			return { ...state, error: null, loading: { ...state.loading, updateUser: false } };

		case TYPE_START_UPDATE_AVATAR:
			return { ...state, error: null, loading: { ...state.loading, updatePhoto: true } };
		case TYPE_FINISHED_UPDATE_AVATAR:
			return { ...state, error: null, loading: { ...state.loading, updatePhoto: false } };

		case TYPE_UPDATE_SUBSCRIPTION_STATUS:

			return {
				...state,
				accountInfo: {
					...state.accountInfo,
					subscription: { ...state.accountInfo.subscription, status: payload.newStatus }
				}
			};
		case TYPE_UPDATE_ACCESS_STATUS:
			return {
				...state,
				accountInfo: {
					...state.accountInfo,
					has_access: payload.hasAccess
				}
			};

		case TYPE_CLEAR_DISCOUNT_INFO:
			return {
				...state,
				accountInfo: {
					...state.accountInfo,
					subscription: {
						...state.accountInfo.subscription,
						discount_info: null // Обнуляем информацию о скидке
					}
				}
			};


		default:
			return state;
	}
};
export default userReducers;
