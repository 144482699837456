import {
	TYPE_SET_MY_INVOICES,
	TYPE_SET_MY_SUBSCRIPTION,
	TYPE_SET_SUBSCRIPTION_PLAN,
	TYPE_UPDATE_MODAL,
	TYPE_SET_MY_CARDS, TYPE_UPDATE_CARD, TYPE_ADD_CARD, TYPE_DELETE_CARD
} from '../actions';


const initialState = {
    plans: [],
    mySubscription: {},
    invoices: [],
    purchaseModal: false,
    cards: []
};

const paymentReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case TYPE_SET_SUBSCRIPTION_PLAN:
            return { ...state, plans: payload.plans };
        case TYPE_SET_MY_SUBSCRIPTION:
            return { ...state, mySubscription: payload.subscription };

        case  TYPE_SET_MY_INVOICES:
            return { ...state, invoices: payload.invoices };
        case TYPE_UPDATE_MODAL:
            return { ...state, purchaseModal: payload.purchaseModal };

        case TYPE_SET_MY_CARDS:
            return { ...state, cards: payload.cards };

        case TYPE_UPDATE_CARD:
            return { ...state };

        case TYPE_ADD_CARD:
            return { ...state };

	    case TYPE_DELETE_CARD:
		    return {
			    ...state,
			    cards: {
				    ...state.cards,
				    payment_methods: (state?.cards?.payment_methods || []).filter(p => p.id !== payload)
			    }
		    };

        default:
            return state;
    }
};

export default paymentReducer;
