export const TYPE_ADD_TEXT_CHUNK = 'TYPE_ADD_TEXT_CHUNK';

export const TYPE_DELETE_CHUNKS = 'TYPE_DELETE_CHUNKS';


export const addChunkText = (messages) => {
	return dispatch => {

		dispatch({
			type: TYPE_ADD_TEXT_CHUNK,
			payload: messages
		});
	};
};
