import { Typography, Box, Grid, IconButton, styled, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { ArrowLeftFolder, DriveEmptyIcon, UploadIcon } from '../../../static/svg';
import { bindActionCreators } from 'redux';
import * as actions from '../../../store/actions';
import { connect, useDispatch } from 'react-redux';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import DriveUploadDocument from './DriveUploadDocument';
import { useNavigate } from 'react-router-dom';
import FileItem from '../../../components/FileItem';
import ModalFileRename from './ModalFileRename';
import SuspenseLoader from '../../../components/SuspenseLoader';
import useHotjar from '../../../hooks/hotjar';
import { Helmet } from 'react-helmet-async';


const ButtonSelectFilter = styled(Button)(
	({ theme, selectedTimeFilter }) => ({
		margin: 0,
		// padding: 0,
		// lineHeight: '0px',
		minWidth: '0px',
		marginLeft: '4px',
		paddingTop: theme.spacing(0.7),
		paddingBottom: theme.spacing(0.7),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		color: selectedTimeFilter ? 'black' : '#667085',
		borderRadius: '8px',
		fontWeight: 400,
		fontSize: 14,
		background: selectedTimeFilter ? '#FFFFFF' : 'transparent',

		'&:hover': {
			background: 'white'
		}
	})
);


const DATAFiler = [{
	id: '',
	name: 'All'
},
	{
		id: '1_week',
		name: '1 Week'
	},
	{
		id: '2_weeks',
		name: '2 Week'
	},
	{
		id: '1_month',
		name: '1 Month'
	}];

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '80%', // начнем с 80% для мобильных устройств
	maxWidth: 600, // максимальная ширина будет 600px
	bgcolor: 'background.paper',
	borderRadius: '16px',
	p: 3,

	'@media (min-width:600px)': { // для устройств с экраном от 600px и выше
		width: 600 // фиксированная ширина 600px для больших экранов
	},
	'@media (max-width:599px)': { // для устройств с экраном до 599px
		width: '90%' // ширина 90% для меньших экранов
	}
};

function FolderDetail({ getFilesByFolder, drive, uploadFile, uploadOneFile }) {
	const { id } = useParams();

	const [status, setStatus] = useState('start');
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [isDragOver, setDragOver] = useState(false);
	const [uploadProgress, setUploadProgress] = useState(0);

	const [fileSelected, setFileSelect] = React.useState({});

	const [openFileRename, setOpenFileRename] = useState(false);

	const [loading, setLoading] = useState(false);


	const handleDragOver = (e) => {
		e.preventDefault();
		setDragOver(true);
	};

	const handleDragLeaveOrDrop = (e) => {
		e.preventDefault();
		setDragOver(false);
	};

	const handleDrop = async (e) => {
		e.preventDefault();
		if (e.dataTransfer.files && e.dataTransfer.files.length > 0 && status !== 'upload') {
			setStatus('upload');
			setUploadProgress(0);
			const fileName = e.dataTransfer.files[0].name.split('.').slice(0, -1).join('.');

			await uploadOneFile(e.dataTransfer.files[0], fileName, setUploadProgress, id);
			setStatus('finished');
		}
		setDragOver(false);
	};

	useHotjar();


	const handleFileSelect = async (event) => {
		if (event.target.files && event.target.files.length > 0) {
			setStatus('upload');
			setUploadProgress(0);

			const fileName = event.target.files[0].name.split('.').slice(0, -1).join('.');
			await uploadOneFile(event.target.files[0], fileName, setUploadProgress, id);
			setStatus('finished');
		}
	};


	const onUpdateFileName = async (values) => {
		try {
			await dispatch(actions.updateFileName(fileSelected?.id, { name: values.name }, Boolean(id)));

			setOpenFileRename(false);
		} catch (error) {

		}
	};

	const handleDeleteFile = () => {
		dispatch(actions.deleteFileDrive(fileSelected.id, Boolean(id)));
	};

	const handleRemoveFromFolder = () => {
		dispatch(actions.removeFromFolder(fileSelected.id));
	};


	const [selectedTimeFilter, setSelectedTimeFilter] = useState('');
	const [openCreateFileModal, setOpenCreateFileModal] = useState(false);

	useEffect(() => {
		const getData = async () => {
			try {
				setLoading(true);
				await Promise.all([
					getFilesByFolder(id, { time_filter: selectedTimeFilter })
				]);
			} catch (e) {
				console.log(e);
			} finally {
				setLoading(false);
			}
		};
		getData();
	}, [selectedTimeFilter, getFilesByFolder, id]);
	return (
		<>
			<Helmet>
				<title>{drive?.currentFolderName || 'Folder'}</title>
			</Helmet>
			{loading ? <SuspenseLoader /> : (<Grid sx={{ p: 2 }}>
				<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>

					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<Typography sx={{ cursor: 'pointer' }} variant={'h3'}
						            onClick={() => navigate('/drive')}>All</Typography>
						<Box sx={{ ml: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
							<ArrowLeftFolder />
						</Box>
						<Typography variant={'h4'}
						            sx={{ ml: 1, fontSize: '20px' }}>{drive.currentFolderName.slice(0, 24)}</Typography>
					</Box>

					<Grid item sx={{ pr: 1 }}>
						<Box display={'flex'} sx={{
							flexDirection: {
								xs: 'column',
								sm: 'row'
							}
						}}>
							<Box sx={{
								background: '#EAECF0',
								// flexDirection:'row',
								display: 'flex',
								padding: '6px',
								borderRadius: '8px',
								flexDirection: {
									xs: 'column',
									sm: 'row'
								}
							}}>
								{DATAFiler.map((item) => (
									<ButtonSelectFilter selectedTimeFilter={item.id === selectedTimeFilter}
									                    onClick={() => setSelectedTimeFilter(item.id)}>{item.name}</ButtonSelectFilter>
								))}

							</Box>
							<Box sx={{ display: 'flex', alignItems: 'center' }}>

								<IconButton
									sx={{
										background: 'linear-gradient(99.97deg, #64ABFF 0%, #444CC6 102.14%)',
										p: 1.4,
										ml: 2
									}}
									color="primary"
									onClick={() => setOpenCreateFileModal(true)}
								>
									<UploadIcon />
								</IconButton>
							</Box>
						</Box>
					</Grid>

				</Box>
				<Grid container>
					{drive.fileByFolder.length > 0 ? drive.fileByFolder.map((item) => {
						return (<FileItem file={item}
						                  setSelectedFile={setFileSelect}
						                  onRenameSubmit={() => setOpenFileRename(true)}
						                  folderId={id}
						                  onMoveTo={handleRemoveFromFolder}
						                  onDeleteFile={handleDeleteFile}
						/>);
					}) : (<Box sx={{
						display: 'flex', justifyContent: 'center',
						flexDirection: 'column',
						alignItems: 'center',
						width: '100%',
						mt: 10
					}}>
						<Box sx={{
							background: '#E0E8F3',
							borderRadius: 999,
							minWidth: '100px',
							width: '120px',
							height: '120px',
							minHeight: '100px',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center'
						}}>
							<DriveEmptyIcon height={'64'} width={'64'} />
						</Box>
						<Typography sx={{
							textAlign: 'center',
							mt: 3
						}} variant={'h3'}>Folder Empty</Typography>
						<Box sx={{ mt: 1.5 }}>
							<Typography sx={{
								textAlign: 'center',
								fontSize: '12px',
								color: '#667085'
							}}>Click Upload to add documents to Drive for storage.
							</Typography>
							<Typography sx={{
								textAlign: 'center',
								fontSize: '12px',
								color: '#667085'
							}}>
								Documents uploaded to Tasks will be automatically added to Drive.
							</Typography>
							<Typography sx={{
								textAlign: 'center',
								fontSize: '12px',
								color: '#667085'
							}}>
								Previously uploaded documents can be selected in Tasks.
							</Typography>
						</Box>
						<Button onClick={() => setOpenCreateFileModal(true)} sx={{
							background: 'linear-gradient(99.97deg, #64ABFF 0%, #444CC6 102.14%)',
							color: 'white',
							mt: 3.5,
							fontSize: 14,
							pl: 3,
							pr: 3,
							pt: 0.7,
							pb: 0.7
						}}>Upload</Button>

					</Box>)
					}
				</Grid>

				<Modal
					open={openCreateFileModal}
					onClose={() => setOpenCreateFileModal(false)}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
					sx={{
						'& .MuiPaper-root': {
							borderRadius: '15px' // Set the border-radius value as you desire
						}
					}}
				>
					<Box sx={style}>
						<IconButton
							onClick={() => {
								setOpenCreateFileModal(false);
								setStatus('start');
							}}
							style={{
								position: 'absolute',
								top: 10,
								right: 10,
								color: '#D0D5DD'
							}}
						>
							<CloseIcon />
						</IconButton>
						<Typography variant={'h3'} sx={{ mb: 2 }}>
							Upload Document
						</Typography>
						<DriveUploadDocument
							handleDragOver={handleDragOver}
							handleDragLeaveOrDrop={handleDragLeaveOrDrop}
							handleDrop={handleDrop}
							isDragOver={isDragOver}
							uploadProgress={uploadProgress}
							handleFileSelect={handleFileSelect}
							status={status}
							uploadFile={uploadFile}
							setStatus={setStatus}
							onCloseModal={() => {
								setOpenCreateFileModal(false);
								setStatus('start');
							}}
						/>
					</Box>

				</Modal>

				<Modal
					open={openFileRename}
					onClose={() => setOpenFileRename(false)}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<ModalFileRename
						initialValues={fileSelected}
						handleClose={() => setOpenFileRename(false)}
						onSubmit={onUpdateFileName} />

				</Modal>


			</Grid>)}
		</>
	);
}

const mapStateToProps = ({ drive, uploadFile }) => ({ drive, uploadFile });

const mapActionToProps = dispatch =>
	bindActionCreators(
		{
			getFilesByFolder: actions.getFilesByFolder,
			uploadOneFile: actions.uploadOneFile
		},
		dispatch
	);

export default connect(mapStateToProps, mapActionToProps)(FolderDetail);
