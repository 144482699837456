import axios from '../../utils/axios';
import { toast } from 'react-toastify';


export const TYPE_SET_TEMPLATE_LIST = 'TYPE_SET_TEMPLATE_LIST';

export const TYPE_RESET_TEMPLATE = 'TYPE_RESET_TEMPLATE';

export const TYPE_DELETE_TEMPLATE = 'TYPE_DELETE_TEMPLATE';

export const TYPE_DELETE_CURRENT_TEMPLATE = 'TYPE_DELETE_CURRENT_TEMPLATE';

export const TYPE_UPDATE_TEMPLATE_TITLE = 'TYPE_UPDATE_TEMPLATE_TITLE';

export const TYPE_ADD_NEW_TEMPLATE = 'TYPE_ADD_NEW_TEMPLATE';

export const TYPE_DISABLE_LOADING = 'TYPE_DISABLE_LOADING';

export const TYPE_SET_LOADING = 'TYPE_SET_LOADING';

export const TYPE_DISABLE_LOADING_CREATE = 'TYPE_DISABLE_LOADING_CREATE';
export const TYPE_SET_LOADING_CREATE = 'TYPE_SET_LOADING_CREATE';


export const getTemplate = (isRefresh = true) => {
	return async dispatch => {
		try {
			const payload = await axios.get(`/api/v1/draft/template-list`);
			dispatch({
				type: TYPE_SET_TEMPLATE_LIST,
				payload: {
					template: payload?.results,
					isRefresh: isRefresh
				}
			});
			// return payload.results
		} catch (e) {
			toast.error(`An error has occurred`, {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true

			});
		} finally {
			dispatch({
				type: TYPE_DISABLE_LOADING
			});

		}
	};
};


export const getDetailTemplate = (id) => {
	return async dispatch => {
		try {
			const payload = await axios.get(`/api/v1/draft/template/${id}`);
			dispatch({
				type: TYPE_RESET_TEMPLATE,
				payload: payload
			});
			return payload;
		} catch (e) {
			toast.error(`An error has occurred`, {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true

			});
		}
	};
};

export const saveFile = (id, editorContent) => {
	return async dispatch => {
		try {
			dispatch({
				type: TYPE_SET_LOADING_CREATE
			});

			await axios.post(`/api/v1/draft/template/${id}/save`, { content: editorContent });
			//
			// return payload
		} catch (e) {
			toast.error(`An error has occurred`, {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true

			});
		} finally {
			dispatch({
				type: TYPE_DISABLE_LOADING_CREATE
			});
		}
	};
};


export const downloadFile = (id, editorContent) => {
	return async dispatch => {
		try {
			const response = await axios.post(
				`/api/v1/draft/template/${id}/download`,
				{ content: editorContent },
				{ responseType: 'blob' }
			);
			const blob = new Blob([response], { type: 'application/pdf' });

			const link = document.createElement('a');
			link.href = URL.createObjectURL(blob);
			link.download = 'document.pdf';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);

		} catch (e) {
			toast.error(`An error has occurred`, {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true

			});
		}
	};
};


export const sendPromptMessage = (prompt, message) => {
	return async dispatch => {
		try {
			const alg = JSON.parse(localStorage.getItem('alg-editor'));
			const data = await axios.post(`/api/v1/chatgpt/send_message_to_gpt`, {
				prompt: prompt,
				message: message,
				type: alg.type
			});
			//
			// dispatch({
			//   type: TYPE_RESET_TEMPLATE,
			//   payload: payload,
			// });
			return data;
		} catch (e) {
			toast.error(`The number of characters exceeds the maximum limit for drafting. Please try again.`, {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true
			});
		}
	};
};


export const addTemplate = (title, content) => {
	return async dispatch => {
		try {
			const payload = await axios.post(`/api/v1/draft/template-list`, { title: title, content: content });
			dispatch({
				type: TYPE_ADD_NEW_TEMPLATE,
				payload: {
					newTemplate: payload
				}
			});
			return payload;
		} catch (e) {
			toast.error(`An error has occurred`, {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true

			});
		}
	};
};


export const updateTemplateName = (id, title) => {
	return async dispatch => {
		try {
			await axios.put(`/api/v1/draft/template/${id}/update-name`, { title: title });
			dispatch({
				type: TYPE_UPDATE_TEMPLATE_TITLE,
				payload: {
					id: id,
					title: title
				}
			});
			// return payload.results
		} catch (e) {
			toast.error(`An error has occurred`, {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true

			});
		}
	};
};


export const deleteCurrentTemplate = () => {
	return async dispatch => {
		try {
			dispatch({
				type: TYPE_DELETE_CURRENT_TEMPLATE
			});
		} catch (e) {
			toast.error(`An error has occurred`, {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true

			});
		}
	};
};


export const deleteTemplate = (id) => {
	return async dispatch => {
		try {
			const payload = await axios.delete(`/api/v1/draft/template/${id}`);
			dispatch({
				type: TYPE_DELETE_TEMPLATE,
				payload: { templateId: id }
			});
			return payload;
		} catch (e) {
			toast.error(`An error has occurred`, {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true

			});
		}
	};
};
