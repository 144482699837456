import { FILE_UPLOAD_SUCCESS, TYPE_DELETE_ALL_FILE, TYPE_DELETE_FILE } from '../actions';


const initialState = {
	uploadedFiles: []
};

const uploadFileReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case FILE_UPLOAD_SUCCESS:

			return { uploadedFiles: [payload] };

		case TYPE_DELETE_FILE:
			return { uploadedFiles: state.uploadedFiles.filter(file => file.id !== payload.id) };

		case TYPE_DELETE_ALL_FILE:
			return { uploadedFiles: [] };


		// case  TYPE_CREATE_TASK:
		// return {drive: [...[payload], ...state.drive]}

		default:
			return state;
	}
};
export default uploadFileReducer;
