import {
	TYPE_CREATE_BUTTON,
	TYPE_DELETE_BUTTON,
	TYPE_SET_BUTTON,
	TYPE_SET_CATEGORY_PROMPT,
	TYPE_SET_NUMBER,
	TYPE_UPDATE_BUTTON_BY_ID
} from '../actions';


const initialState = {
	buttons: [],
	numbers: [],
	category: []
};

const buttonReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case TYPE_SET_BUTTON:
			if (payload.isRefresh) {
				return { ...state, buttons: payload.buttons };
			}
			return { ...state, buttons: [...state.buttons, ...payload.buttons] };
		case  TYPE_SET_NUMBER:
			return { ...state, numbers: payload.numbers };
		case TYPE_CREATE_BUTTON:
			return { ...state, buttons: [...[payload], ...state.buttons] };
		case TYPE_DELETE_BUTTON:
			const newArray = state.buttons.filter((item) => item.id !== payload.buttonId);
			return { ...state, buttons: newArray };

		case TYPE_SET_CATEGORY_PROMPT:
			if (payload.isRefresh) {
				return { ...state, category: payload.category };
			}
			return { ...state, category: [...state.category, ...payload.category] };

		case TYPE_UPDATE_BUTTON_BY_ID:
			return {
				...state,
				buttons: state.buttons.map(button => {
					if (button.id === payload.id) {
						return {
							...button,
							title: payload.title,
							text_gpt: payload.text_gpt
						};
					}
					return button; // Возвращаем button без изменений, если это не искомый элемент
				})
			};

		default:
			return state;
	}
};

export default buttonReducer;
