import { Box, Typography, Grid, IconButton, Menu } from '@mui/material';
import PdfFileIcon from '../../static/image/pdfFile.png';
import DocFileIcon from '../../static/image/docFile.png';
import { DotHoverIcon, FileFlagIcon } from '../../static/svg';
import { formatDate } from '../../utils/date';
import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import PreviewPdf from '../../content/applications/Drive/PreviewPdf';
import Modal from '@mui/material/Modal';


function FileItem({
	                  file,
	                  setSelectedFile,
	                  onRenameSubmit,
	                  onDeleteFile

                  }) {
	const [previewModal, setPreviewModal] = useState(false);


	const [anchorElFile, setAnchorElFile] = React.useState(null);

	const openMenuFile = Boolean(anchorElFile);

	const handleClickFile = (event, task) => {
		setAnchorElFile(event.currentTarget);
		setSelectedFile(task);
	};

	const handleCloseMenuFile = (event) => {
		event.stopPropagation();
		setAnchorElFile(null);
	};
	return (
		<Grid sx={{
			pt: 2,
			pb: 2,
			pl: 1,
			pr: 1
		}} item xs={6} sm={4} md={3} lg={2}>

			<Box sx={{
				background: '#D8E4F2',
				borderRadius: '8px',
				position: 'relative', // Add position to make IconButton absolute
				'&:hover': {
					background: '#A6CAF5',
					'& .MuiIconButton-root': {
						opacity: 1
					}
				}
			}}
			     onClick={() => file.type === '.pdf' ? setPreviewModal(true) : window.open(file.results_image, '_blank', 'noopener,noreferrer')}>
				<IconButton sx={{
					position: 'absolute',
					top: 0,
					right: 0,
					opacity: 0
				}}
				            onClick={(event) => {
					            // event.preventDefault()
					            event.stopPropagation();
					            handleClickFile(event, file);
				            }}>
					<DotHoverIcon color={'#619EE7'} />
				</IconButton>

				<Menu
					id="basic-menu"
					anchorEl={anchorElFile}
					open={openMenuFile}
					onClose={handleCloseMenuFile}
					MenuListProps={{
						'aria-labelledby': 'basic-button'
					}}
					sx={{
						p: 0,
						'& .MuiMenuItem-root:hover': {
							backgroundColor: '#fff' // Здесь укажите цвет, который должен быть при наведении
						}
					}}
				>
					<MenuItem sx={{
						p: 0,
						pt: 0.5,
						pb: 0.5, pr: 4
					}}
					          onClick={(event) => {
						          onRenameSubmit();
						          handleCloseMenuFile(event);
					          }}> <Typography sx={{
						fontWeight: '500'
					}}>Rename File</Typography>
					</MenuItem>

					<MenuItem sx={{
						p: 0,
						pt: 0.5,
						pb: 0.5, pr: 4
					}}
					          onClick={(event) => {
						          event.stopPropagation();
						          window.open(file?.file, '_blank', 'noopener,noreferrer');

					          }}>
						<Typography sx={{
							fontWeight: '500'
						}}>
							Download
						</Typography>
					</MenuItem>


					{/*  <MenuItem  sx={{*/}
					{/*    p:0,*/}
					{/*    pt:0.5,*/}
					{/*    pb:0.5,pr:4*/}
					{/*}}*/}
					{/*    onClick={(event)=>{*/}
					{/*        onMoveTo()*/}
					{/*        handleCloseMenuFile(event)*/}
					{/*    }}> <Typography sx={{*/}
					{/*        fontWeight:'500'*/}
					{/*     }}>{folderId ? "Remove from folder" : "Move To"}</Typography>*/}
					{/*</MenuItem>*/}
					<MenuItem sx={{
						p: 0,
						pt: 0.5,
						pb: 0.5,
						pr: 4,
						'& .MuiMenuItem-root:active': {
							backgroundColor: '#fff' // Здесь укажите цвет, который должен быть при наведении
						}

					}} onClick={(event) => {

						onDeleteFile();
						handleCloseMenuFile(event);

					}}>
						<Typography sx={{
							color: '#F04438',
							fontWeight: '500',
							'& .MuiMenuItem-root:active': {
								backgroundColor: '#fff' // Здесь укажите цвет, который должен быть при наведении
							}
						}}>Delete File</Typography>
					</MenuItem>
				</Menu>


				<Box sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'flex-end',
					p: 4,
					pb: 1
				}}>
					<Box sx={{
						width: '70%',
						maxHeight: '130px',
						overflow: 'hidden',
						borderRadius: '9px',
						display: 'flex',
						justifyContent: 'center'
					}}>
						{file.type === '.pdf' && (
							<img style={{
								width: '90%',
								height: 'auto',
								objectFit: 'contain',
								maxWidth: '80%'
							}}
							     src={PdfFileIcon} alt={'pdf-file'} />)
						}
						{file.type === '.docx' && (
							<img style={{
								width: '90%',
								height: 'auto',
								objectFit: 'contain',
								maxWidth: '80%'
							}}
							     src={DocFileIcon} alt="doc-file" />)
						}
					</Box>
				</Box>

				<Box
					sx={{
						// background: '#D8E4F2',
						pl: '20px',
						pr: '20px',
						pt: 2,
						pb: 2
					}}
				>
					<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
						<Box sx={{ display: 'flex', alignItems: 'flex-start', paddingTop: '2px' }}>
							<FileFlagIcon />
						</Box>
						<Box sx={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'flex-start',
							ml: 1,
							maxWidth: '90%'
						}}>
							<Typography
								sx={{
									fontWeight: '600',
									fontSize: 16,
									whiteSpace: 'nowrap',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									maxWidth: '100%'
								}}
							>
								{file.name}
							</Typography>
							<Typography
								sx={{ fontSize: '11px', color: '#667085', display: { xs: 'none', md: 'block' } }}>
								{`Last update ${formatDate(file?.updated_at)}`}
							</Typography>
						</Box>
					</Box>
				</Box>
			</Box>

			<Modal
				open={previewModal}
				onClose={() => setPreviewModal(false)}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>

				<PreviewPdf file={file?.file}
				            onClose={() => {
					            setPreviewModal(false);
				            }}
				/>
			</Modal>


		</Grid>
	);
}

export default FileItem;
