import { TYPE_SET_CURRENT_TASK } from '../actions';


const initialState = {};

const currentTask = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case TYPE_SET_CURRENT_TASK:
			return { ...payload };
		default:
			return state;
	}
};

export default currentTask;
