import axios from '../../utils/axios';
import { toast } from 'react-toastify';
import { TYPE_SET_FILE_EXIST } from './tips';


export const TYPE_SET_DRIVE = 'TYPE_SET_DRIVE';

export const TYPE_SET_TASK_FILE = 'TYPE_SET_TASK_FILE';

export const TYPE_DELETE_TASK_FILES = 'TYPE_DELETE_TASK_FILES';

export const TYPE_ADD_TASK_FILES = 'TYPE_ADD_TASK_FILES';
export const TYPE_CREATE_FOLDER = 'CREATE_FOLDER';
export const TYPE_CREATE_FILE = 'TYPE_CREATE_FILE';
export const TYPE_SET_FILE_BY_FOLDER = 'TYPE_SET_FILE_BY_FOLDER';

export const TYPE_CREATE_FILE_BY_FOLDER = 'TYPE_CREATE_FILE_BY_FOLDER';

export const TYPE_UPDATE_FOLDER_NAME = 'TYPE_UPDATE_FOLDER_NAME';

export const TYPE_DELETE_FOLDER = 'TYPE_DELETE_FOLDER';

export const TYPE_UPDATE_FOLDER_FILE_NAME = 'TYPE_UPDATE_FOLDER_FILE_NAME';

export const TYPE_UPDATE_FILE_NAME = 'TYPE_UPDATE_FILE_NAME';


export const TYPE_DELETE_FOLDER_FILE = 'TYPE_DELETE_FOLDER_FILE';

export const TYPE_DELETE_DRIVE_FILE = 'TYPE_DELETE_DRIVE_FILE';


export const TYPE_CLEAR_FOLDER_FILES = 'TYPE_CLEAR_FOLDER_FILES';


export const getDriveList = (isRefresh = false, params = {}) => {
	return async dispatch => {
		try {
			const payload = await axios.get(`/api/v1/chatgpt/folder-list`, {
				params
			});

			dispatch({
				type: TYPE_SET_DRIVE,
				payload: {
					folder: [],
					file: payload,
					isRefresh: true
				}
			});
		} catch (e) {
			toast.error(`An error has occurred`, {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true

			});
		}
	};
};


export const getTaskStoreList = (chatId, isRefresh = false, params = {}) => {
	return async dispatch => {
		dispatch({ type: TYPE_DELETE_TASK_FILES });
		try {
			const payload = await axios.get(`/api/v1/chatgpt/task-store-list/${chatId}`, {
				params
			});

			dispatch({
				type: TYPE_SET_TASK_FILE,
				payload: {
					file: payload,
					isRefresh: true
				}
			});
			return payload.length || 0;
		} catch (e) {
			return 0;
		}
	};
};

export const addFormDriveFile = (chatId, fileId, file) => {
	return async dispatch => {
		try {
			dispatch({ type: TYPE_SET_FILE_EXIST, payload: true });
			const isUUID = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(fileId);

			// Строгая проверка на целое число

			let contentType;
			if (isUUID) {
				// Если fileId - UUID
				contentType = 'document';
			} else {
				// Если fileId не соответствует ни одному из условий
				contentType = 'archivefile'; // Или обработать как ошибку
			}

			await axios.post(`/api/v1/chatgpt/task-store-list/${chatId}`, {
				content_type: contentType,
				object_id: fileId
			});


		} catch (e) {

		} finally {

			dispatch({
				type: TYPE_ADD_TASK_FILES,
				payload: {
					file: file
				}
			});

		}
	};
};


export const addNewStorFile = (data) => {
	return async dispatch => {
		try {
			dispatch({
				type: TYPE_ADD_TASK_FILES,
				payload: {
					file: data
				}
			});

			dispatch({ type: TYPE_SET_FILE_EXIST, payload: true });


		} catch (e) {
			toast.error(`An error has occurred`, {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true

			});
		}
	};
};


export const createFolder = (data) => {
	return async dispatch => {
		try {
			const payload = await axios.post(`/api/v1/chatgpt/folder-list`, data);

			dispatch({
				type: TYPE_CREATE_FOLDER,
				payload: {
					folder: payload
				}
			});
		} catch (e) {
			toast.error(`An error has occurred`, {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true

			});
		}
	};
};

export const updateNameFolder = (folderId, data) => {
	return async dispatch => {
		try {
			await axios.put(`/api/v1/chatgpt/folder/${folderId}/change_folder`, data);

			dispatch({
				type: TYPE_UPDATE_FOLDER_NAME,
				payload: {
					id: folderId,
					name: data.name
				}
			});
		} catch (e) {
			toast.error(`An error has occurred`, {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true

			});
		}
	};
};


export const updateFileName = (fileId, data, isFolder = false) => {
	return async dispatch => {
		try {
			await axios.put(`/api/v1/chatgpt/archive/${fileId}/change_file`, data);

			const actionType = isFolder ? TYPE_UPDATE_FOLDER_FILE_NAME : TYPE_UPDATE_FILE_NAME;

			dispatch({
				type: actionType,
				payload: {
					id: fileId,
					name: data.name
				}
			});
		} catch (e) {
			toast.error(`An error has occurred`, {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true

			});
		}
	};
};


export const deleteFolder = (folderId) => {
	return async dispatch => {
		try {
			await axios.delete(`/api/v1/chatgpt/folder/${folderId}/change_folder`);

			dispatch({
				type: TYPE_DELETE_FOLDER,
				payload: {
					id: folderId
				}
			});
		} catch (e) {
			toast.error(`An error has occurred`, {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true

			});
		}
	};
};


export const getFilesByFolder = (folderId, params = {}, isRefresh = false) => {
	return async dispatch => {
		try {
			const payload = await axios.get(`/api/v1/chatgpt/folder/${folderId}`, {
				params
			});

			dispatch({
				type: TYPE_SET_FILE_BY_FOLDER,
				payload: {
					file: payload.results,
					folderName: payload.folder_name,
					isRefresh: true
				}
			});
		} catch (e) {
			alert('Error Task');
		}
	};
};


export const deleteFileDrive = (fileId, isFolder = false) => {
	return async (dispatch) => {
		try {
			await axios.delete(`/api/v1/chatgpt/archive/${fileId}`);
			const actionType = isFolder ? TYPE_DELETE_FOLDER_FILE : TYPE_DELETE_DRIVE_FILE;

			dispatch({
				type: actionType,
				payload: { id: fileId }
			});
		} catch (error) {
			console.log(error);
		}
	};
};


export const moveToFolder = (fileId, data) => {
	return async (dispatch) => {
		try {
			await axios.put(`/api/v1/chatgpt/archive/${fileId}/move_to_folder`, data);

			dispatch({
				type: TYPE_DELETE_DRIVE_FILE,
				payload: { id: fileId }
			});

		} catch (error) {
			console.log(error);
		}
	};
};


export const removeFromFolder = (fileId) => {
	return async (dispatch) => {
		try {
			await axios.delete(`/api/v1/chatgpt/archive/${fileId}/remove_folder`);

			dispatch({
				type: TYPE_DELETE_FOLDER_FILE,
				payload: { id: fileId }
			});

		} catch (error) {
			console.log(error);
		}
	};
};

export const clearFolder = () => {
	return (dispatch) => {
		dispatch({
			type: TYPE_CLEAR_FOLDER_FILES
		});
	};
};

