import axios from '../../utils/axios';
import { toast } from 'react-toastify';


export const createLink = (data) => {
	return async dispatch => {
		try {
			const payload = await axios.post(`/api/v1/chatgpt/link`, data);
			return payload;

		} catch (e) {
			toast.error(` This URL has been blocked by a provider for external use or does not have any text content.
        We are working on updating our URL library very soon.`, {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true
			});
		}
	};
};

