import { useContext } from 'react';

import { alpha, Box, List, styled, Button, ListItem } from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';
import { SidebarContext } from 'src/contexts/SidebarContext';
import { DraftIcon, DriveIcon, SettingIcon, TaskIcon } from '../../../../static/svg';
import { useSelector } from 'react-redux';
import HintCard from '../../../../components/Hint';


const MenuWrapper = styled(Box)(
	({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(1)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.trueWhite[50]};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = styled(Box)(
	({ theme }) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 1px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.trueWhite[70]};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon svg {
            color: ${theme.colors.alpha.trueWhite[30]};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
           stroke: ${theme.colors.alpha.trueWhite[30]};;
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }
          &:hover {
            background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.06)};
            color: ${theme.colors.alpha.trueWhite[100]};
     
           
            .MuiButton-startIcon svg,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.trueWhite[100]};
              stroke: ${theme.colors.alpha.trueWhite[100]};
            }
          }
           &.active {
            background-color: #444CC6;
            color: ${theme.colors.alpha.trueWhite[100]};
            .MuiButton-startIcon svg,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.trueWhite[100]};
              stroke: ${theme.colors.alpha.trueWhite[100]};
            }
           }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
		'transform',
		'opacity'
	])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);

function SidebarMenu() {
	const { closeSidebar } = useContext(SidebarContext);
	const tooltips = useSelector(state => state.tips.tooltips);
	const isHintTooltipVisible = tooltips.hasOwnProperty('hint_two_regular_req') && tooltips['hint_two_regular_req'];

	return (
		<>
			<MenuWrapper>
				<List
					component="div"
				>
					<SubMenuWrapper>
						<List component="div">
							<ListItem component="div">
								<Button
									disableRipple
									component={RouterLink}
									onClick={closeSidebar}
									to="/tasks"
									startIcon={<TaskIcon />}
								>
									Tasks
								</Button>
							</ListItem>

							<ListItem component="div">
								<Button
									disableRipple
									component={RouterLink}
									onClick={closeSidebar}
									to="/draft"
									startIcon={<DraftIcon />}
								>
									Editor
								</Button>
							</ListItem>
							<ListItem component="div">
								<Button
									disableRipple
									component={RouterLink}
									onClick={closeSidebar}
									to="/drive"
									startIcon={<DriveIcon />}
								>
									Library
								</Button>
							</ListItem>

							<ListItem component="div">
								<Button
									disableRipple
									component={RouterLink}
									onClick={closeSidebar}
									to="/settings"
									startIcon={<SettingIcon />}
								>
									Settings
								</Button>
							</ListItem>
						</List>
					</SubMenuWrapper>
				</List>
				<List component="div">
					{isHintTooltipVisible && <HintCard />}
				</List>


				{/*{pinTask.length > 0 && (<List*/}
				{/*    component="div"*/}
				{/*    subheader={*/}
				{/*        <Typography sx={{ml: 1, color: '#98A2B3', fontWeight: "400", fontSize: "14px"}}>Pinned*/}
				{/*            Tasks</Typography>*/}
				{/*    }*/}
				{/*>*/}
				{/*    {pinTask.map((item) => {*/}
				{/*        return (<List component="div" sx={{padding: '8px'}}>*/}
				{/*            <ListItemButton*/}
				{/*                onClick={() => {*/}
				{/*                                  dispatch(addRecentlyTask(item))*/}

				{/*                                  navigate(`/tasks/messenger/${item.id}`);*/}

				{/*                              }}*/}
				{/*                // component="div"*/}
				{/*                sx={{*/}
				{/*                    display: 'flex',*/}
				{/*                    alignItems: 'center',*/}
				{/*                    paddingLeft: '20px',*/}
				{/*                }}*/}
				{/*            >*/}
				{/*                <ChatPinIcon/>*/}
				{/*                <Typography*/}
				{/*                    sx={{*/}
				{/*                        color: '#98A2B3',*/}
				{/*                        fontWeight: '400',*/}
				{/*                        fontSize: '16px',*/}
				{/*                        flex: 1,*/}
				{/*                        overflow: 'hidden',*/}
				{/*                        textOverflow: 'ellipsis',*/}
				{/*                        whiteSpace: 'nowrap',*/}
				{/*                        marginLeft: '8px',*/}
				{/*                    }}*/}
				{/*                >*/}
				{/*                    {item.name}*/}
				{/*                </Typography>*/}
				{/*                <PinIcon color={"#D0D5DD"}/>*/}
				{/*            </ListItemButton>*/}
				{/*        </List>)*/}
				{/*    })}*/}

				{/*</List>)}*/}
			</MenuWrapper>
		</>
	);
}

export default SidebarMenu;
