import {
	TYPE_ADD_NEW_TEMPLATE,
	TYPE_DELETE_CURRENT_TEMPLATE,
	TYPE_DELETE_TEMPLATE,
	TYPE_DISABLE_LOADING,
	TYPE_DISABLE_LOADING_CREATE,
	TYPE_RESET_TEMPLATE,
	TYPE_SET_LOADING,
	TYPE_SET_LOADING_CREATE,
	TYPE_SET_TEMPLATE_LIST,
	TYPE_UPDATE_TEMPLATE_TITLE
} from '../actions';


const initialState = {
	isLoading: true,
	isLoadingCreate: false,
	template: [],
	currentTemplatyById: {}
};

const draftReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case TYPE_SET_TEMPLATE_LIST:
			if (payload.isRefresh) {
				return { ...state, template: payload.template };
			}
			return { ...state, template: [...state.template, ...payload.template] };

		case TYPE_RESET_TEMPLATE:
			return {
				...state, currentTemplatyById: {
					[payload.id]: payload
				}
			};

		case TYPE_DELETE_CURRENT_TEMPLATE:
			return { ...state, currentTemplatyById: {} };

		case TYPE_UPDATE_TEMPLATE_TITLE:

			return {
				...state,
				template: state.template.map(item =>
					item.id === Number(payload.id)
						? { ...item, title: payload.title }
						: item
				)
			};

		case TYPE_ADD_NEW_TEMPLATE:
			return {
				...state,
				template: [payload.newTemplate, ...state.template]
			};

		case TYPE_DISABLE_LOADING:
			return {
				...state,
				isLoading: false
			};

		case TYPE_SET_LOADING:
			return {
				...state,
				isLoading: true
			};

		case TYPE_DISABLE_LOADING_CREATE:
			return {
				...state,
				isLoadingCreate: false
			};

		case TYPE_SET_LOADING_CREATE:
			return {
				...state,
				isLoadingCreate: true
			};

		case TYPE_DELETE_TEMPLATE:
			return {
				...state,
				template: state.template.filter(item => item.id !== payload.templateId)
			};

		default:
			return state;
	}
};
export default draftReducer;
