import axios from '../../utils/axios';
import { toast } from 'react-toastify';


export const TYPE_SET_CONTENT_FILE = 'TYPE_SET_CONTENT_FILE';


export const getContentFile = (contentId) => {
	return async dispatch => {
		try {
			const payload = await axios.get(`/api/v1/chatgpt/message-content/${contentId}`);

			dispatch({
				type: TYPE_SET_CONTENT_FILE,
				payload: payload
			});

		} catch (e) {
			toast.error(`An error has occurred`, {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true
			});
		}
	};
};
