import React, { useEffect, useState } from 'react';
import {
	Box,
	Card,
	CardContent,
	CircularProgress,
	CssBaseline,
	Grid,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	MenuItem,
	OutlinedInput,
	Paper,
	Select,
	Typography
} from '@mui/material';
import Button from '@mui/material/Button';
import { ChevronIcon, ListCheckIcon, Logo, NextLogInIcon } from '../../../static/svg';
import { bindActionCreators } from 'redux';
import * as actions from '../../../store/actions';
import { connect } from 'react-redux';
import { CardNumberElement, Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import axios from '../../../utils/axios';


const stipePublishKey = process.env.REACT_APP_STIPE_PUBLISH_KEY || '';
const stripePromise = loadStripe(stipePublishKey);

export function PlanCard({ plan, isLoading, handleCheckout }) {

	return (
		// <Grid item xs={12} sm={6} md={3}>
		<Card sx={{
			boxShadow: '0px 0px 1px 0px #2426293D',
			borderRadius: '18px',
			background: plan?.is_checked ? '#64ABFF' : 'white',
			height: '100%',
			display: 'flex',
			flexDirection: 'column',
			'&:hover': {
				background: '#64ABFF',
				border: 'none',
				'& .card-hover-button': {
					background: '#444CC6',
					border: 'none'
				},
				'& .list-hover-button': {
					background: '#64ABFF'
				},
				'& .card-hover-text': {
					color: 'white'
				},
				'& .hover-change': {
					fill: 'white',
					opacity: 1
				}
			}
		}}>
			<CardContent sx={{ p: 0, display: 'flex', flexDirection: 'column', height: '100%' }}>
				<Grid container alignItems={'center'} justifyContent={'space-between'} sx={{ p: 2 }}>
					<Grid item>
						<Box sx={{ borderRadius: '8px' }}>
							<Typography className={'card-hover-text'} variant="h5" component="div" sx={{
								fontWeight: '700',
								fontSize: '22px',
								color: plan?.is_checked ? 'white' : 'black'
							}}>
								{plan.name}
							</Typography>
						</Box>
					</Grid>
				</Grid>

				<Box display={'flex'} alignItems={'baseline'}
				     sx={{ borderBottom: `1px solid #EAECF0`, pb: 2, pl: 2, pr: 2 }}>
					{plan.calculated_discount_price ? (
						<Box sx={{ display: 'flex', alignItems: 'center' }} className={'card-hover-text'}>
							<Typography className={'card-hover-text'} variant="h4"
							            sx={{ color: 'grey', textDecoration: 'line-through', mr: 1 }}>
								${Math.floor(plan.price)}
							</Typography>
							<Typography className={'card-hover-text'} variant="h3"
							            sx={{ fontWeight: '700', color: plan?.is_checked ? 'white' : 'black' }}>
								${Math.floor(plan.calculated_discount_price)}
							</Typography>
						</Box>
					) : (
						<Typography className={'card-hover-text'} variant="h3"
						            sx={{ fontWeight: '700', color: plan?.is_checked ? 'white' : 'black' }}>
							${Math.floor(plan.price)}
						</Typography>
					)}
					<Typography className={'card-hover-text'}
					            sx={{ color: plan?.is_checked ? 'white' : '#475467', mt: 1 }}>
						/{plan.duration}
					</Typography>

				</Box>

				<Box sx={{
					display: 'flex',
					flex: 1,
					p: 2,
					justifyContent: 'center',

					overflowY: 'auto'
				}}>

					<List className={'list-hover-button'} sx={{
						background: plan?.is_checked ? '#64ABFF' : '#f6f7ff',
						borderRadius: '8px',
						width: '90%'
					}}>
						{plan.features.map((feature, index) => (
							<ListItem key={index} sx={{
								p: 0.3
							}}>
								<ListItemIcon sx={{ minWidth: '30px' }}>
									<ListCheckIcon is_checked={plan?.is_checked} />
								</ListItemIcon>
								<ListItemText sx={{
									color: plan?.is_checked ? 'white' : 'black'
								}} primary={feature.replace(/\./g, ',')}
								              className={'card-hover-text'} />
							</ListItem>
						))}
					</List>

				</Box>
				<Box sx={{
					display: 'flex',
					justifyContent: 'center'
				}}>
					<Button disabled={plan?.is_checked} className="card-hover-button" sx={{
						border: !plan?.is_checked && '1px solid #D0D5DD',
						width: '90%',
						background: plan?.is_checked ? '#444CC6' : 'white'
					}} onClick={() => handleCheckout(plan.id)}>
						{isLoading ? (
							<CircularProgress size={22} />// Показать индикатор загрузки, если isLoading true
						) : (
							<Typography className={'card-hover-text'} sx={{
								fontWeight: '500',
								color: plan?.is_checked ? 'white' : '#444CC6'
							}}>{plan?.is_checked ? 'Current Plan' : 'Choose Plan'}</Typography>
						)}
					</Button>
				</Box>
				{/*</Box>*/}
			</CardContent>
		</Card>
		// </Grid>
	);
}

function SelectPlan({ payment, getSubscriptionPlan }) {
	const [isPayment] = useState(false);
	const [checkedItem, setCheckedItem] = useState({});


	useEffect(() => {
		(async () => {
			await getSubscriptionPlan();
		})();
	}, [getSubscriptionPlan]);

	async function onSubmit(event) {
		try {
			event.preventDefault();


		} catch (e) {
			console.log(e);
		}

	}

	const handleCheckout = async () => {
		const stripe = await stripePromise;


		const postData = {
			subscriptionPlanId: checkedItem.price_id
		};


		window.ire('generateClickId', function(clickid) {
			postData.impact_clickid = clickid;
		});


		const response = await axios.post(`/api/v1/payment/create-payment-session`, postData);


		const result = await stripe.redirectToCheckout({
			sessionId: response.sessionId
		});
		//
		if (result.error) {
			// Обработка ошибок
			alert(result.error.message);
		}
	};

	const [selectedOption, setSelectedOption] = React.useState('credit_card');

	const handleChange = (event) => {
		setSelectedOption(event.target.value);
	};

	const finalPrice = (parseFloat(checkedItem?.price) - parseFloat(checkedItem?.discount_price));

	return (
		<Elements stripe={stripePromise}>
			{isPayment ? <Grid container component="main"
			                   sx={{ height: '100vh' }}>
					<Grid
						item
						xs={12}
						sm={12}
						md={6}
						lg={6}
						sx={{
							px: 8, py: 4,
							background: '#EFF5FD',
							justifyContent: 'center',
							display: 'flex', // Center the content horizontally
							alignItems: 'center' // Center the content vertically

						}}
					>
						<Box sx={{ position: 'absolute', top: '5vh', left: '4vw' }}>
							<Logo />
						</Box>
						<Box container={true} sx={{ background: 'white', p: 3.2, borderRadius: '20px' }}>
							<Typography variant={'h4'}>
								{checkedItem?.name}
							</Typography>

							<Box display={'flex'} alignItems={'baseline'}
							     sx={{ borderBottom: `1px solid #EAECF0`, pb: 2, pt: 1 }}>
								<Typography variant="h5" sx={{ fontWeight: '500' }}>
									${checkedItem?.price?.replace('.', ',')}
								</Typography>
								<Typography sx={{ color: '#475467', fontSize: '14px' }}>
									/month
								</Typography>
							</Box>

							<List sx={{ p: 0, pt: 1, pb: 2 }}>
								{checkedItem.features.map((feature, index) => (
									<ListItem sx={{ p: 0, pt: 1.5, m: 0 }} key={index}>
										<ListItemIcon sx={{ minWidth: '30px', m: 0 }}>
											<ListCheckIcon />
										</ListItemIcon>
										<ListItemText sx={{ p: 0, m: 0 }} primary={feature} />
									</ListItem>
								))}
							</List>
							<Box sx={{ borderTop: `1px solid #EAECF0`, pt: 2 }}>
								<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
									<Typography sx={{ fontWeight: '400' }}>
										Subtotal
									</Typography>
									<Typography sx={{ fontWeight: '500', fontSize: '18px' }}>
										${checkedItem?.price.replace('.', ',')}
									</Typography>
								</Box>

								{checkedItem?.discount_price ? (
									<Box sx={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
										mb: 2
									}}>
										<Typography sx={{ fontWeight: '400' }}>
											Discount
										</Typography>

										<Typography sx={{ fontWeight: '500', fontSize: '18px', color: '#F04438' }}>
											-${finalPrice.toFixed(1).replace('.', ',')}
										</Typography>

									</Box>) : null}

							</Box>
							<Box sx={{ borderTop: `1px solid #EAECF0` }}>
								<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
									<Typography sx={{ fontWeight: '500' }}>
										Total Due
									</Typography>
									<Typography sx={{ fontWeight: '700', fontSize: '18px' }}>
										${checkedItem?.discount_price ? checkedItem?.discount_price.replace('.', ',') :
										checkedItem?.price.replace('.', ',')}
									</Typography>
								</Box>

							</Box>

						</Box>

					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={6} component={Paper} elevation={6} square>
						<Box
							sx={{
								my: 10,
								mx: 10,
								alignItems: 'center'
							}}
						>
							<Typography component="h2" variant="h2">
								Get Started
							</Typography>
							<Box component="form" noValidate onSubmit={onSubmit} sx={{ mt: 2 }}>

								<Box>
									<Typography sx={{ color: '#101828', mb: 1, fontWeight: '500', fontSize: '14px' }}>Select
										Method</Typography>
									<Select
										value={selectedOption}
										onChange={handleChange}
										displayEmpty
										IconComponent={() => null}
										endAdornment={<ChevronIcon />}

										sx={{
											width: '100%', // Set the select box to be full width
											background: 'white',
											border: '1px solid #D0D5DD',
											'&:focus': {
												background: 'white' // Change the background color when the select is focused
											}
										}}>
										<MenuItem value={'credit_card'}>Credit/Debit Card</MenuItem>
									</Select>

								</Box>
								<Box sx={{ mt: 2 }}>
									<Typography sx={{
										color: '#101828',
										mb: 1,
										fontWeight: '500',
										fontSize: '14px'
									}}>Email</Typography>
									<OutlinedInput
										margin="normal"
										required
										fullWidth
										variant="standard"
										id="first_name"
										label="Full Name"
										name="first_name"
										placeholder="Enter your full name"
										sx={{
											border: '1px solid #EAECF0',
											'& .MuiOutlinedInput-notchedOutline': {
												borderWidth: 0 // Setting border-width to 0 will remove the border
											},
											'& fieldset': { border: 'none' }
										}}
									/>
								</Box>

								<Box sx={{ mt: 2 }}>
									<Typography sx={{ color: '#101828', mb: 1, fontWeight: '500', fontSize: '14px' }}>Card
										Information</Typography>
									<CardNumberElement />
									<Box sx={{ display: 'flex' }}>
										<OutlinedInput
											margin="normal"
											required
											fullWidth
											variant="standard"
											id="first_name"
											label="Full Name"
											name="first_name"
											placeholder="Enter your full name"
											sx={{
												border: '1px solid #EAECF0',
												borderTopRightRadius: 0,
												'& .MuiOutlinedInput-notchedOutline': {
													borderWidth: 0 // Setting border-width to 0 will remove the border
												},
												'& fieldset': { border: 'none' }
											}}
										/>
										<OutlinedInput
											margin="normal"
											required
											fullWidth
											variant="standard"
											id="first_name"
											label="Full Name"
											name="first_name"
											placeholder="Enter your full name"
											sx={{
												border: '1px solid #EAECF0',
												borderTopRightRadius: 0,
												'& .MuiOutlinedInput-notchedOutline': {
													borderWidth: 0 // Setting border-width to 0 will remove the border
												},
												'& fieldset': { border: 'none' }
											}}
										/>
									</Box>
								</Box>

							</Box>
						</Box>

					</Grid>

				</Grid> :
				<Grid container
				      component="main"
				      sx={{ height: '100vh' }}
				      direction="column">
					<CssBaseline />
					<Grid item justifyContent={'center'}>
						<Box sx={{ background: 'white', py: 2, px: 10 }}>
							<Logo />
						</Box>
					</Grid>
					<Grid container spacing={2} alignItems="center"
					      justifyContent="space-between" sx={{ px: 10, py: 2 }}>
						<Box item xs={12} sm={6} md={8} lg={10}>
							<Typography variant={'h1'}>Select Your Plan</Typography>
						</Box>
						<Box item xs={12} sm={6} md={4} lg={2}>
							<Button
								type="submit"
								endIcon={<NextLogInIcon />}
								onClick={() => handleCheckout()}
								// fullWidth
								variant="contained"
								sx={{
									mt: 3, mb: 2,
									background: 'linear-gradient(99.55deg, #276CFF 42.8%, #4A84FF 81.65%)',
									p: 3, borderRadius: 999, pt: 1.2, pb: 1.2
								}}
							>
								<Typography sx={{ fontWeight: '500' }}>Continue to Payment</Typography>
							</Button>
						</Box>
					</Grid>
					<Grid container spacing={2} sx={{ px: '90px' }}>
						{payment.plans.map((plan, index) => (
							<PlanCard key={index} plan={plan} checked={checkedItem}
							          handleCheckChange={setCheckedItem} />
						))}
					</Grid>
				</Grid>
			}
		</Elements>
	);
}


const mapStateToProps = ({ payment }) => ({
	payment
});

const mapActionToProps = dispatch =>
	bindActionCreators(
		{
			getSubscriptionPlan: actions.getSubscriptionPlan
		},
		dispatch
	);

export default connect(mapStateToProps, mapActionToProps)(SelectPlan);
