import { TYPE_ADD_PARTICIPATION, TYPE_SET_PARTICIPATION } from '../actions';


const initialState = [];

const taskParticipationReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case TYPE_SET_PARTICIPATION:
			if (payload.isRefresh) {
				return payload.participation;
			}
			return [...state, ...payload.participation];

		case  TYPE_ADD_PARTICIPATION:
			return [...state, payload];

		default:
			return state;
	}
};

export default taskParticipationReducer;
