import { TYPE_DISABLE_NOTIFICATION_LOADING, TYPE_SET_COUNT, TYPE_SET_NOTIFICATION } from '../actions';


const initialState = {
	isLoading: true,
	template: [],
	count: 0
};

const notificationReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case TYPE_SET_NOTIFICATION:
			if (payload.isRefresh) {
				return { ...state, notification: payload.notification };
			}
			return { ...state, notification: [...state.notification, ...payload.notification] };

		case TYPE_SET_COUNT:
			return { ...state, count: payload.count };

		case TYPE_DISABLE_NOTIFICATION_LOADING:
			return {
				...state,
				isLoading: false
			};
		default:
			return state;
	}
};


export default notificationReducer;
