import {
	TYPE_CREATE_TASK,
	TYPE_DELETE_TASK,
	TYPE_SET_IS_PARTICIPANT,
	TYPE_SET_PIN_POST,
	TYPE_SET_RECENTLY_TASK,
	TYPE_SET_TASK,
	TYPE_SET_UN_PIN_POST,
	TYPE_UPDATE_TASK_NAME
} from '../actions';


const initialState = {
	tasks: [],
	pinTask: [],
	recentlyTask: []
};

const taskReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case TYPE_SET_TASK:
			if (payload.isRefresh) {
				return {
					...state, tasks: payload.tasks,
					pinTask: payload.tasks.filter(post => post.pin === true).slice(0, 5),
					recentlyTask: payload.recentlyTask
				};
			}
			return { ...state, tasks: [...state.tasks, ...payload.tasks] };
		case  TYPE_CREATE_TASK:
			return { ...state, tasks: [...[payload], ...state.tasks] };

		case TYPE_DELETE_TASK:
			return {
				...state,
				tasks: state.tasks.filter((item) => item.id !== payload.id),
				pinTask: state.pinTask.filter((item) => item.id !== payload.id),
				recentlyTask: state.recentlyTask.filter((item) => item.id !== payload.id)
			};

		case TYPE_SET_PIN_POST:
			const taskToPin = state.tasks.find(task => task.id === payload.id);
			if (!taskToPin) {
				return state;
			}

			const updatedTaskToPin = { ...taskToPin, pin: true };
			const updatedTasks = state.tasks.filter(task => task.id !== payload.id);
			const finalUpdatedTasks = [updatedTaskToPin, ...updatedTasks];
			const updatedPinTasks = [updatedTaskToPin, ...state.pinTask].slice(0, 5);


			let updatedRecentlyTasksForPin = state.recentlyTask;
			const foundInRecentlyForPin = state.recentlyTask.some(task => task.id === payload.id);

			const updatedTaskToPinNew = { ...taskToPin, pin: true };

			if (foundInRecentlyForPin) {
				updatedRecentlyTasksForPin = state.recentlyTask.map(task =>
					task.id === payload.id ? updatedTaskToPinNew : task
				);
			}

			return {
				...state,
				tasks: finalUpdatedTasks,
				pinTask: updatedPinTasks,
				recentlyTask: updatedRecentlyTasksForPin
			};

		case TYPE_SET_UN_PIN_POST:
			const taskToUnpin = state.tasks.find(task => task.id === payload.id);

			if (!taskToUnpin) {
				return state;
			}

			const updatedTaskToUnpin = { ...taskToUnpin, pin: false };
			const updatedUnpinTasks = state.pinTask.filter(task => task.id !== payload.id);
			const updatedUnTasks = state.tasks.map(task =>
				task.id === payload.id ? updatedTaskToUnpin : task
			);

			let updatedRecentlyTasksForUnpin = state.recentlyTask;
			const foundInRecentlyForUnpin = state.recentlyTask.some(task => task.id === payload.id);

			const updatedTaskToUnpinNew = { ...taskToUnpin, pin: false };

			if (foundInRecentlyForUnpin) {
				updatedRecentlyTasksForUnpin = state.recentlyTask.map(task =>
					task.id === payload.id ? updatedTaskToUnpinNew : task
				);
			}

			return {
				...state,
				tasks: updatedUnTasks,
				pinTask: updatedUnpinTasks,
				recentlyTask: updatedRecentlyTasksForUnpin
			};

		case TYPE_SET_RECENTLY_TASK:
			const alreadyAdded = state.recentlyTask.some(task => task.id === payload.id);

			if (alreadyAdded) {
				return state;
			}

			// Add the payload (task) to the beginning of the recentlyTask list
			const updatedRecentlyTasks = [payload, ...state.recentlyTask];

			// Ensure that the recentlyTask list contains at most 3 items
			const trimmedRecentlyTasks = updatedRecentlyTasks.slice(0, 3);

			return { ...state, recentlyTask: trimmedRecentlyTasks };

		case TYPE_UPDATE_TASK_NAME:
			const updateTaskName = (tasks) => {
				return tasks.map(task => {
					if (task.id === payload.id) {
						return {
							...task,
							name: payload.name
						};
					}
					return task;
				});
			};

			return {
				...state,
				tasks: updateTaskName(state.tasks),
				pinTask: updateTaskName(state.pinTask),
				recentlyTask: updateTaskName(state.recentlyTask)
			};

		case TYPE_SET_IS_PARTICIPANT:
			const updateIsParticipant = (tasks) => {
				return tasks.map(task => {
					if (task.id === payload.id) {
						return {
							...task,
							is_participant: true
						};
					}
					return task;
				});
			};

			return {
				...state,
				tasks: updateIsParticipant(state.tasks)
			};


		default:
			return state;
	}
};

export default taskReducer;
