import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Box, useTheme } from '@mui/material';
import React, { forwardRef } from 'react';


const Scrollbar = forwardRef(({ className, children, ...rest }, ref) => {
	const theme = useTheme();
	return (
		<Scrollbars
			ref={ref} // Передаем ref в Scrollbars
			autoHide
			renderThumbVertical={() => (
				<Box
					sx={{
						width: 5,
						background: `${theme.colors.alpha.black[10]}`,
						borderRadius: `${theme.general.borderRadiusLg}`,
						transition: `${theme.transitions.create(['background'])}`,
						'&:hover': {
							background: `${theme.colors.alpha.black[30]}`
						}
					}}
				/>
			)}
			{...rest}
		>
			{children}
		</Scrollbars>
	);
});

Scrollbar.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string
};

export default Scrollbar;
