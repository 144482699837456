import axios from '../../utils/axios';
import { toast } from 'react-toastify';


export const TYPE_SET_SELECTED_MESSAGE = 'TYPE_SET_SELECTED_MESSAGE';
export const TYPE_CLEAR_SELECTED_MESSAGE = 'TYPE_CLEAR_SELECTED_MESSAGE';


export const getSelectedChat = (isRefresh = true, chatId, page = 1) => {
	return async dispatch => {
		try {
			const payload = await axios.get(`/api/v1/chatgpt/chat-message`,
				{ params: { chat: chatId, page: page } });
			dispatch({
				type: TYPE_SET_SELECTED_MESSAGE,
				payload: {
					messages: payload?.results,
					isRefresh: isRefresh
				}
			});
			return payload?.results?.length || 0;
		} catch (e) {
			toast.error(`An error has occurred`, {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true

			});
		} finally {

		}
	};
};

export const createPdfFile = (data) => {
	return async dispatch => {
		try {
			const response = await axios.post(
				`/api/v1/chatgpt/create-message-pdf`,
				data,
				{ responseType: 'blob' }
			);
			const blob = new Blob([response], { type: 'application/pdf' });

			const link = document.createElement('a');
			link.href = URL.createObjectURL(blob);
			link.download = 'messages.pdf';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);

		} catch (e) {
			toast.error(`An error has occurred`, {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true

			});
		}
	};
};


export const clearSelectedMessage = () => {
	return async dispatch => {
		try {
			dispatch({
				type: TYPE_CLEAR_SELECTED_MESSAGE
			});
		} catch (e) {

		}
	};
};
